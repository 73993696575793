import { MessagesActionTypes, MessagesAction } from './GetMessagesActionTypes';

export const messagesRequest = (data: any): MessagesAction => ({
  type: MessagesActionTypes.MESSAGES_REQUEST,
  payload: data
});

export const messagesRequestSuccess = (data: any): MessagesAction => ({
  type: MessagesActionTypes.MESSAGES_REQUEST_SUCCESS,
  payload: data
});

export const messagesRequestError = (error: string): MessagesAction => ({
  type: MessagesActionTypes.MESSAGES_REQUEST_ERROR,
  payload: error
});

export const updateMessagesRequest = (data: any): MessagesAction => ({
  type: MessagesActionTypes.UPDATE_MESSAGES_REQUEST,
  payload: data
});

export const updateMessagesRequestSuccess = (data: any): MessagesAction => ({
  type: MessagesActionTypes.UPDATE_MESSAGES_REQUEST_SUCCESS,
  payload: data
});

export const updateMessagesRequestError = (error: string): MessagesAction => ({
  type: MessagesActionTypes.UPDATE_MESSAGES_REQUEST_ERROR,
  payload: error
});

export const deleteMessagesRequest = (data: any): MessagesAction => ({
  type: MessagesActionTypes.DELETE_MESSAGES_REQUEST,
  payload: data
});

export const deleteMessagesRequestSuccess = (data: any): MessagesAction => ({
  type: MessagesActionTypes.DELETE_MESSAGES_REQUEST_SUCCESS,
  payload: data
});

export const deleteMessagesRequestError = (error: string): MessagesAction => ({
  type: MessagesActionTypes.DELETE_MESSAGES_REQUEST_ERROR,
  payload: error
});
