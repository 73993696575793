import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { FollowTeacherActionTypes } from '../actions/followTeacherActionTypes';
import { followTeacherError, followTeacherSuccess } from '../actions/followTeacherActions';

function* followTeacherSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.followTeacher, req.payload);
    // Dispatch the success action with the API response
    yield put(followTeacherSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(followTeacherError(error.message));
  }
}

export function* watchFollowTeacher() {
  yield takeLatest(FollowTeacherActionTypes.FOLLOW_TEACHER_REQUEST, followTeacherSaga);
}
