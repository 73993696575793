export enum UserMetricsActionTypes {
  USER_METRICS_REQUEST = 'userMetrics/USER_METRICS_REQUEST',
  USER_METRICS_REQUEST_SUCCESS = 'userMetrics/USER_METRICS_REQUEST_SUCCESS',
  USER_METRICS_REQUEST_ERROR = 'userMetrics/USER_METRICS_REQUEST_ERROR'
}

export interface UserMetricsAction {
  type: UserMetricsActionTypes;
  payload?: any;
}
