// features/createClass/actions/createClassActions.ts
import { getEventsActionTypes, getEventsAction } from './getEventsActionTypes';

export const getEventsRequest = (data: any): getEventsAction => ({
  type: getEventsActionTypes.GET_EVENTS,
  payload: data
});

export const getEventsRequestSuccess = (data: any): getEventsAction => ({
  type: getEventsActionTypes.GET_EVENTS_SUCCESS,
  payload: data
});

export const getEventsRequestError = (error: string): getEventsAction => ({
  type: getEventsActionTypes.GET_EVENTS_ERROR,
  payload: error
});
