// features/registerUser/actions/RegisterActions.ts
import { ConfirmRegistrationActionTypes, ConfirmRegistrationAction } from "./ConfirmRegistrationActionTypes";

export const confirmRegistration = (data: any): ConfirmRegistrationAction => ({
  type: ConfirmRegistrationActionTypes.CONFIRM_REGISTRATION,
  payload: data,
});

export const confirmRegistrationSuccess = (data: any): ConfirmRegistrationAction => ({
  type: ConfirmRegistrationActionTypes.CONFIRM_REGISTRATION_SUCCESS,
  payload: data,
});

export const confirmRegistrationError = (error: string): ConfirmRegistrationAction => ({
  type: ConfirmRegistrationActionTypes.CONFIRM_REGISTRATION_ERROR,
});