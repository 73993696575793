import { CreateClassActionTypes, CreateClassAction } from '../actions/CreateClassActionTypes';

interface CreateClassState {
  data: string[];
  loading: boolean;
  error: string | null;
  posted: boolean;
}

const initialState: CreateClassState = {
  data: [],
  loading: false,
  error: null,
  posted: false
};

const createClassReducer = (state = initialState, action: CreateClassAction): CreateClassState => {
  switch (action.type) {
    case CreateClassActionTypes.SUBMIT_FORM:
      return { ...state, loading: true, posted: false };
    case CreateClassActionTypes.SUBMIT_FORM_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null, posted: true };
    case CreateClassActionTypes.SUBMIT_FORM_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default createClassReducer;
