import { ClassModelsActionTypes, ClassModelsAction } from './ClassModelsActionTypes';

export const classModelsRequest = (data: any): ClassModelsAction => ({
  type: ClassModelsActionTypes.CLASS_MODELS_REQUEST,
  payload: data
});

export const classModelsRequestSuccess = (data: any): ClassModelsAction => ({
  type: ClassModelsActionTypes.CLASS_MODELS_REQUEST_SUCCESS,
  payload: data
});

export const classModelsRequestError = (error: string): ClassModelsAction => ({
  type: ClassModelsActionTypes.CLASS_MODELS_REQUEST_ERROR,
  payload: error
});
