import { FollowTeacherAction, FollowTeacherActionTypes } from '../actions/followTeacherActionTypes';

interface FollowTeacherState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: FollowTeacherState = {
  data: {},
  loading: false,
  error: null
};

const followTeacherReducer = (state = initialState, action: FollowTeacherAction): FollowTeacherState => {
  switch (action.type) {
    case FollowTeacherActionTypes.FOLLOW_TEACHER_REQUEST:
      return { ...state, loading: true, data: action.payload };
    case FollowTeacherActionTypes.FOLLOW_TEACHER_REQUEST_SUCCESS:
      return { ...state, loading: false, error: null };
    case FollowTeacherActionTypes.FOLLOW_TEACHER_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default followTeacherReducer;
