import { FollowTeacherAction, FollowTeacherActionTypes } from './followTeacherActionTypes';

export const followTeacher = (data: any): FollowTeacherAction => ({
  type: FollowTeacherActionTypes.FOLLOW_TEACHER_REQUEST,
  payload: data
});

export const followTeacherSuccess = (data: any): FollowTeacherAction => ({
  type: FollowTeacherActionTypes.FOLLOW_TEACHER_REQUEST_SUCCESS,
  payload: data
});

export const followTeacherError = (error: string): FollowTeacherAction => ({
  type: FollowTeacherActionTypes.FOLLOW_TEACHER_REQUEST_ERROR,
  payload: error
});
