import { takeLatest, put } from 'redux-saga/effects';
import { ResendCodeActionTypes } from '../actions/ResendCodeActionTypes';
import { resendCodeUserSuccess, resendCodeUserError } from '../actions/resendCodeActions';

const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-1' });
const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

function* resendCodeUserSaga(action) {
  try {
    const req = action.payload;

    const resend_code_params = {
      ClientId: localStorage.getItem('client_id'),
      Username: req.email.split('@')[0]
    };
    // Simulate an asynchronous API call
    const authPromise = new Promise(async (resolve, reject) => {
      await cognitoIdentityServiceProvider.resendConfirmationCode(resend_code_params, (err, result: any) => {
        if (err) {
          console.log('Password Request failed:', err);
          reject(err);
          localStorage.setItem('password_resent', 'false');
        } else {
          localStorage.setItem('password_resent', 'true');
          resolve({ result });
        }
      });
    });
    try {
      const authResult = yield authPromise;
      yield put(resendCodeUserSuccess(authResult));
    } catch (error: any) {
      yield put(resendCodeUserError(error));
    }
  } catch (error: any) {
    yield put(resendCodeUserError(error));
  }
}

export function* watchResendCodeUser() {
  yield takeLatest(ResendCodeActionTypes.VERIFY_PASSWORD_USER, resendCodeUserSaga);
}
