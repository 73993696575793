export enum ProfileActionTypes {
  PROFILE_REQUEST = 'getProfile/PROFILE_REQUEST',
  PROFILE_REQUEST_SUCCESS = 'getProfile/PROFILE_REQUEST_SUCCESS',
  PROFILE_REQUEST_ERROR = 'getProfile/PROFILE_REQUEST_ERROR',
  USER_PROFILE_REQUEST = 'getProfile/USER_PROFILE_REQUEST',
  USER_PROFILE_REQUEST_SUCCESS = 'getProfile/USER_PROFILE_REQUEST_SUCCESS',
  USER_PROFILE_REQUEST_ERROR = 'getProfile/USER_PROFILE_REQUEST_ERROR',
  UPDATE_PROFILE_REQUEST = 'getProfile/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_REQUEST_SUCCESS = 'getProfile/UPDATE_PROFILE_REQUEST_SUCCESS',
  UPDATE_PROFILE_REQUEST_ERROR = 'getProfile/UPDATE_PROFILE_REQUEST_ERROR',
  DELETE_PROFILE_REQUEST = 'getProfile/DELETE_PROFILE_REQUEST',
  DELETE_PROFILE_REQUEST_SUCCESS = 'getProfile/DELETE_PROFILE_REQUEST_SUCCESS',
  DELETE_PROFILE_REQUEST_ERROR = 'getProfile/DELETE_PROFILE_REQUEST_ERROR'
}

export interface ProfileAction {
  type: ProfileActionTypes;
  payload?: any;
}
