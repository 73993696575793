export enum makePaymentActionTypes {
  CREATE_PAYMENT_INTENT = 'makePayment/CREATE_PAYMENT_INTENT',
  CREATE_PAYMENT_INTENT_SUCCESS = 'makePayment/CREATE_PAYMENT_INTENT_SUCCESS',
  CREATE_PAYMENT_INTENT_ERROR = 'makePayment/CREATE_PAYMENT_INTENT_ERROR',
  MAKE_PAYMENT = 'makePayment/MAKE_PAYMENT',
  MAKE_PAYMENT_SUCCESS = 'makePayment/MAKE_PAYMENT_SUCCESS',
  MAKE_PAYMENT_ERROR = 'makePayment/MAKE_PAYMENT_ERROR'
}

export interface makePaymentAction {
  type: makePaymentActionTypes;
  payload?: any;
}
