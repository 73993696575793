import { useState } from 'react';
import GlobalStyles from './globalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProviderWrapper from './defaultTheme/ThemeProvider';
import Router from './Components/router';
import { useRoutes } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useDispatch } from 'react-redux';
import { logoutThunk } from './features/otherReducers/Gen3LearningActions';
import { AppDispatch } from './store/configureStore';
import { useEffect } from 'react';
import axios from 'axios';

function requestNotificationPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.log('Notification permission denied.');
    }
  });
}

function App() {
  const routing = useRoutes(Router);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration) {
      const expirationTime = parseInt(tokenExpiration, 10);
      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime > expirationTime) {
        dispatch(logoutThunk());
        localStorage.clear();
        window.location.assign('/home');
      }
    }
  };

  const [notification, setNotification]: any = useState(null);

  useEffect(() => {
    // Automatically request notification permission when the app loads
    requestNotificationPermission();
  }, []);

  useEffect(() => {
    // Check if service worker and messaging is supported
    if ('serviceWorker' in navigator) {
      // Add an event listener for messages from the service worker
      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Received message from service worker:', event);
        if (event.data && event.data.type === 'PUSH_NOTIFICATION_RECEIVED') {
          // Update the state with the received notification data
          setNotification({
            title: event.data.title,
            body: event.data.body
          });
        }
      });
    }
  }, []);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.onmessage = function (event) {
      console.log('Received message from service worker:', event);
      if (event.data && event.data.type === 'SUBSCRIBE_URL') {
        const subscribeURL = event.data.subscribeURL;
        console.log('Received SubscribeURL from service worker:', subscribeURL);

        // Optionally: Display the subscribe URL or confirm it manually
        confirmSubscription(subscribeURL);
      }
    };
  }

  // Function to manually confirm the subscription (if needed)
  function confirmSubscription(subscribeURL) {
    console.log('Confirming subscription:', subscribeURL);
    fetch(subscribeURL, { method: 'GET' })
    .then((response) => {
      if (response.ok) {
        console.log('Subscription confirmed successfully.');
      } else {
        console.error('Failed to confirm subscription.');
      }
    })
    .catch((error) => {
      console.error('Error confirming subscription:', error);
    });
  }

  return (
    <HelmetProvider>
      <ThemeProviderWrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <CssBaseline />
            {GlobalStyles}
            <ScrollToTop>{routing}</ScrollToTop>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProviderWrapper>
    </HelmetProvider>
  );
}

export default App;
