import { ManageLocationActionTypes, ManageLocationAction } from '../actions/manageLocationActionTypes';

interface ManageLocationState {
  data: string[];
  managing: boolean;
  manage_error: string | null;
}

const initialState: ManageLocationState = {
  data: [],
  managing: false,
  manage_error: null
};

const manageLocationReducer = (state = initialState, action: ManageLocationAction): ManageLocationState => {
  switch (action.type) {
    case ManageLocationActionTypes.MANAGE_LOCATION:
      return { ...state, managing: true };
    case ManageLocationActionTypes.MANAGE_LOCATION_SUCCESS:
      return { ...state, data: action.payload, managing: false, manage_error: null };
    case ManageLocationActionTypes.MANAGE_LOCATION_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
      case ManageLocationActionTypes.CREATE_LOCATION:
      return { ...state, managing: true };
    case ManageLocationActionTypes.CREATE_LOCATION_SUCCESS:
      return { ...state, data: action.payload, managing: false, manage_error: null };
    case ManageLocationActionTypes.CREATE_LOCATION_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
      case ManageLocationActionTypes.UPDATE_LOCATION:
      return { ...state, managing: true };
    case ManageLocationActionTypes.UPDATE_LOCATION_SUCCESS:
      return { ...state, data: action.payload, managing: false, manage_error: null };
    case ManageLocationActionTypes.UPDATE_LOCATION_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
      case ManageLocationActionTypes.DELETE_LOCATION:
      return { ...state, managing: true };
    case ManageLocationActionTypes.DELETE_LOCATION_SUCCESS:
      return { ...state, data: action.payload, managing: false, manage_error: null };
    case ManageLocationActionTypes.DELETE_LOCATION_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
    default:
      return state;
  }
};

export default manageLocationReducer;
