// features/createClass/actions/createClassActions.ts
import { CreateClassActionTypes, CreateClassAction } from "./CreateClassActionTypes";

export const createClassRequest = (data: any): CreateClassAction => ({
  type: CreateClassActionTypes.SUBMIT_FORM,
  payload: data,
});

export const createClassRequestSuccess = (data: any): CreateClassAction => ({
  type: CreateClassActionTypes.SUBMIT_FORM_SUCCESS,
  payload: data,
});

export const createClassRequestError = (error: string): CreateClassAction => ({
  type: CreateClassActionTypes.SUBMIT_FORM_ERROR,
  payload: error,
});
