const ENV = process.env.REACT_APP_LANE;
const BASE_URL = `https://${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_LANE}`; // Replace with your actual API base URL
const HW_URL = `https://8omwyqhaqd.execute-api.us-east-1.amazonaws.com/Production`;

export const apiService = {
  login: async (req: any) => {
    // Simulate an asynchronous API callu
    const response = await fetch(`${BASE_URL}/authenticateuser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: req?.useremail,
        password: req?.password
      })
    });
    return response.json();
  },
  registerUser: async (req: any) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/registeruser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: req?.email,
        password: req?.password,
        phoneNumber: req?.phone_number,
        registration_code: req?.registration_code
      })
    });
    return response.json();
  },
  ForgotPwd: async (req: any) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/forgotpassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: req.email
      })
    });
    return response.json();
  },
  courseListing: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/getCourseList`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  courseDetail: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/getCourseDetail`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  classModels: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/getClassModel`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  classNotes: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/GetNotes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  userTasks: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/manageTasks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  ConfirmRegistration: async (req: any) => {
    const response = await fetch(`${BASE_URL}/ConfirmRegistration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  EventRegistration: async (req: any) => {
    const response = await fetch(`${BASE_URL}/RegisterEvents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  MakePayment: async (req: any) => {
    const response = await fetch(`${BASE_URL}/MakePayment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  UserMetrics: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/UserMetrics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  createClass: async (req: any) => {
    const response = await fetch(`${req?.formData?.user_api}/${ENV}/ProvideClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getResources: async (req: any) => {
    const response = await fetch(`${BASE_URL}/getResources`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });

    return response.json();
  },
  getLocations: async (req: any) => {
    const response = await fetch(`${BASE_URL}/getLocations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });

    return response.json();
  },
  manageResources: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/manageResources`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageCourse: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/manageCourse`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageCategories: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/manageCategories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageLocations: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/manageLocations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getEvents: async (req: any) => {
    const response = await fetch(`${BASE_URL}/GetEvents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageEvents: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/UpdateEvents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  createHomework: async (req: any) => {
    const response = await fetch(`${req?.formData?.user_api}/${ENV}/CreateHomework`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  enrollClass: async (req: any) => {
    const response = await fetch(`${req?.formData?.user_api}/${ENV}/EnrollClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getStudents: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/GetStudents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getClasses: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/GetClasses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getProfile: async (req: any) => {
    const response = await fetch(`${req.user_api}/${ENV}/getprofile?userId=${req.userId}&profile=${req.groups}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      }
    });

    return response.json();
  },
  getUserProfile: async (req: any) => {
    const response = await fetch(`${BASE_URL}/GetProfile?userId=${req.userId}&profile=${req.groups}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.json();
  },
  getHomework: async (req: any) => {
    const response = await fetch(
      `https://x1bw1o4ak9.execute-api.us-east-1.amazonaws.com/Production/?requestor=${req.requestor}&student_id=${req.student_id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${req.idToken}`
        }
      }
    );

    return response.json();
  },
  getHomeworkPages: async (req: any) => {
    const response = await fetch(
      `${HW_URL}/?request=${req.request}&homework_id=${req.homework_id}&student_id=${req.student_id}&requestor_type=${req.requestor_type}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${req.idToken}`
        }
      }
    );

    return response.json();
  },
  getHomeworkQuestions: async (req: any) => {
    const response = await fetch(
      `${HW_URL}?request=${req.request}&hw_question_id=${req.student_homework_id}_${req.homework_date_page}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${req.idToken}`
        }
      }
    );

    return response.json();
  },
  updateHomeworkQuestions: async (req: any) => {
    const update_hw_url = 'https://ud8exw7bf2.execute-api.us-east-2.amazonaws.com/Production';
    var hw_obj = {
      request_type: req.request_type,
      hw_question_id: req.hw_question_id,
      question_number: req.question_number,
      student_answers: req.student_answers,
      variance: req.variance,
      student_id: req.student_id,
      homework_id: req.homework_id
    };
    const response = await fetch(update_hw_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(hw_obj)
    });

    return response.json();
  },
  updateProfile: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/UpdateProfile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  updateClass: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/updateClasses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  requestClass: async (req: any) => {
    const response = await fetch(`${req?.formData?.user_api}/${ENV}/RequestClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getMessages: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/GetMessages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  updateMessages: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/UpdateMessages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  sendNotifications: async (req: any) => {
    const response = await fetch(`${BASE_URL}/Notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getAvailability: async (req: any) => {
    const response = await fetch(`${req?.data?.user_api}/${ENV}/GetAvailability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.data?.user_id}`
      },
      body: JSON.stringify(req)
    });
    const resp = await response.json();
    return resp;
  },
  setAvailability: async (req: any) => {
    const response = await fetch(`${req?.availability_header_payload?.user_api}/${ENV}/UpdateAvailability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.availability_header_payload?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getSchedule: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/getSchedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getTutors: async (req: any) => {
    const response = await fetch(`${BASE_URL}/GetTutors`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  submitTutorRequest: async (req: any) => {
    const response = await fetch(`${BASE_URL}/SubmitTutorRequest`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getJoinTeam: async (req: any) => {
    const response = await fetch(`${BASE_URL}/JoinTeam`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  gen3Rewards: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/GetRewards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  gen3HomePage: async () => {
    const response = await fetch(`${BASE_URL}/getHomePageData`, {
      method: 'POST',
      body: JSON.stringify({})
    });
    return response.json();
  },
  requestCourseToLocation: async (req: any) => {
    const response = await fetch(`${BASE_URL}/bring_course`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  requestCourseReschedule: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/GetRescheduleRequests`, {
      method: 'POST',
      body: JSON.stringify(req),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  },
  followTeacher: async (req: any) => {
    // TODO: fix api
    const response = await fetch(`${BASE_URL}/follow_teacher`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  courseNotification: async (req: any) => {
    // TODO: fix api
    const response = await fetch(`${BASE_URL}/course_notification`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  }
};
