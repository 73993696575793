export enum MessagesActionTypes {
    MESSAGES_REQUEST = 'getMessages/MESSAGES_REQUEST',
    MESSAGES_REQUEST_SUCCESS = 'getMessages/MESSAGES_REQUEST_SUCCESS',
  MESSAGES_REQUEST_ERROR = 'getMessages/MESSAGES_REQUEST_ERROR',
  UPDATE_MESSAGES_REQUEST = 'getMessages/UPDATE_MESSAGES_REQUEST',
  UPDATE_MESSAGES_REQUEST_SUCCESS = 'getMessages/UPDATE_MESSAGES_REQUEST_SUCCESS',
  UPDATE_MESSAGES_REQUEST_ERROR = 'getMessages/UPDATE_MESSAGES_REQUEST_ERROR',    
  DELETE_MESSAGES_REQUEST = 'getMessages/DELETE_MESSAGES_REQUEST',
  DELETE_MESSAGES_REQUEST_SUCCESS = 'getMessages/DELETE_MESSAGES_REQUEST_SUCCESS',
  DELETE_MESSAGES_REQUEST_ERROR = 'getMessages/DELETE_MESSAGES_REQUEST_ERROR',      
  }
  
  export interface MessagesAction {
    type: MessagesActionTypes;
    payload?: any;
  }
  