export enum HomeworkActionTypes {
    HOMEWORK_REQUEST = 'getHomework/HOMEWORK_REQUEST',
    HOMEWORK_REQUEST_SUCCESS = 'getHomework/HOMEWORK_REQUEST_SUCCESS',
  HOMEWORK_REQUEST_ERROR = 'getHomework/HOMEWORK_REQUEST_ERROR',
  UPDATE_HOMEWORK_REQUEST = 'getHomework/UPDATE_HOMEWORK_REQUEST',
  UPDATE_HOMEWORK_REQUEST_SUCCESS = 'getHomework/UPDATE_HOMEWORK_REQUEST_SUCCESS',
  UPDATE_HOMEWORK_REQUEST_ERROR = 'getHomework/UPDATE_HOMEWORK_REQUEST_ERROR',    
  DELETE_HOMEWORK_REQUEST = 'getHomework/DELETE_HOMEWORK_REQUEST',
  DELETE_HOMEWORK_REQUEST_SUCCESS = 'getHomework/DELETE_HOMEWORK_REQUEST_SUCCESS',
  DELETE_HOMEWORK_REQUEST_ERROR = 'getHomework/DELETE_HOMEWORK_REQUEST_ERROR',      
  }
  
  export interface HomeworkAction {
    type: HomeworkActionTypes;
    payload?: any;
  }
  