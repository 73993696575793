export enum HomeworkPagesActionTypes {
    HOMEWORK_PAGES_REQUEST = 'getHomeworkPages/HOMEWORK_PAGES_REQUEST',
    HOMEWORK_PAGES_REQUEST_SUCCESS = 'getHomeworkPages/HOMEWORK_PAGES_REQUEST_SUCCESS',
  HOMEWORK_PAGES_REQUEST_ERROR = 'getHomeworkPages/HOMEWORK_PAGES_REQUEST_ERROR',
  UPDATE_HOMEWORK_PAGES_REQUEST = 'getHomeworkPages/UPDATE_HOMEWORK_PAGES_REQUEST',
  UPDATE_HOMEWORK_PAGES_REQUEST_SUCCESS = 'getHomeworkPages/UPDATE_HOMEWORK_PAGES_REQUEST_SUCCESS',
  UPDATE_HOMEWORK_PAGES_REQUEST_ERROR = 'getHomeworkPages/UPDATE_HOMEWORK_PAGES_REQUEST_ERROR',    
  DELETE_HOMEWORK_PAGES_REQUEST = 'getHomeworkPages/DELETE_HOMEWORK_PAGES_REQUEST',
  DELETE_HOMEWORK_PAGES_REQUEST_SUCCESS = 'getHomeworkPages/DELETE_HOMEWORK_PAGES_REQUEST_SUCCESS',
  DELETE_HOMEWORK_PAGES_REQUEST_ERROR = 'getHomeworkPages/DELETE_HOMEWORK_PAGES_REQUEST_ERROR',      
  }
  
  export interface HomeworkPagesAction {
    type: HomeworkPagesActionTypes;
    payload?: any;
  }
  