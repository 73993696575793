import { takeLatest, put, call } from 'redux-saga/effects';
import { HomeworkQuestionsActionTypes } from '../actions/HomeworkQuestionsActionTypes';
import {
  homeworkQuestionsRequestSuccess,
  homeworkQuestionsRequestError,
  updateHomeworkQuestionsRequestSuccess,
  updateHomeworkQuestionsRequestError,
  deleteHomeworkQuestionsRequestSuccess,
  deleteHomeworkQuestionsRequestError
} from '../actions/homeworkQuestionsActions';
import { apiService } from '../../../services/api';

function* homeworkQuestionsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getHomeworkQuestions, req.payload);

    // Dispatch the success action with the API response
    yield put(homeworkQuestionsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(homeworkQuestionsRequestError(error.message));
  }
}

function* updateHomeworkQuestionsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.updateHomeworkQuestions, req.payload);

    // Dispatch the success action with the API response
    yield put(updateHomeworkQuestionsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateHomeworkQuestionsRequestError(error.message));
  }
}

function* deleteHomeworkQuestionsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getHomeworkQuestions, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteHomeworkQuestionsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteHomeworkQuestionsRequestError(error.message));
  }
}

export function* watchHomeworkQuestionsRequest() {
  yield takeLatest(HomeworkQuestionsActionTypes.HOMEWORK_QUESTIONS_REQUEST, homeworkQuestionsRequestSaga);
  yield takeLatest(HomeworkQuestionsActionTypes.UPDATE_HOMEWORK_QUESTIONS_REQUEST, updateHomeworkQuestionsRequestSaga);
  yield takeLatest(HomeworkQuestionsActionTypes.DELETE_HOMEWORK_QUESTIONS_REQUEST, deleteHomeworkQuestionsRequestSaga);
}
