import { StudentsActionTypes, StudentsAction } from './GetStudentsActionTypes';

export const studentsRequest = (data: any): StudentsAction => ({
  type: StudentsActionTypes.STUDENTS_REQUEST,
  payload: data
});

export const studentsRequestSuccess = (data: any): StudentsAction => ({
  type: StudentsActionTypes.STUDENTS_REQUEST_SUCCESS,
  payload: data
});

export const studentsRequestError = (error: string): StudentsAction => ({
  type: StudentsActionTypes.STUDENTS_REQUEST_ERROR,
  payload: error
});

export const updateStudentsRequest = (data: any): StudentsAction => ({
  type: StudentsActionTypes.UPDATE_STUDENTS_REQUEST,
  payload: data
});

export const updateStudentsRequestSuccess = (data: any): StudentsAction => ({
  type: StudentsActionTypes.UPDATE_STUDENTS_REQUEST_SUCCESS,
  payload: data
});

export const updateStudentsRequestError = (error: string): StudentsAction => ({
  type: StudentsActionTypes.UPDATE_STUDENTS_REQUEST_ERROR,
  payload: error
});

export const deleteStudentsRequest = (data: any): StudentsAction => ({
  type: StudentsActionTypes.DELETE_STUDENTS_REQUEST,
  payload: data
});

export const deleteStudentsRequestSuccess = (data: any): StudentsAction => ({
  type: StudentsActionTypes.DELETE_STUDENTS_REQUEST_SUCCESS,
  payload: data
});

export const deleteStudentsRequestError = (error: string): StudentsAction => ({
  type: StudentsActionTypes.DELETE_STUDENTS_REQUEST_ERROR,
  payload: error
});
