import { takeLatest, put, call } from 'redux-saga/effects';
import { TutorsActionTypes } from '../actions/getTutorActionTypes';
import {
  tutorsRequestSuccess,
  tutorsRequestError,
  submitTutorsRequestSuccess,
  submitTutorsRequestError
} from '../actions/getTutorActions';
import { apiService } from '../../../services/api';

function* tutorsRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getTutors, req.payload);

    // Dispatch the success action with the API response
    yield put(tutorsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(tutorsRequestError(error.message));
  }
}

function* submitTutorsRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.submitTutorRequest, req.payload);

    // Dispatch the success action with the API response
    yield put(submitTutorsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(submitTutorsRequestError(error.message));
  }
}

export function* watchTutorsRequest() {
  yield takeLatest(TutorsActionTypes.TUTORS_REQUEST, tutorsRequestSaga);
  yield takeLatest(TutorsActionTypes.SUBMIT_TUTORS_REQUEST, submitTutorsRequestSaga);
}
