import { JoinTeamActionTypes, JoinTeamAction } from './getJoinTeamActionTypes';

export const joinTeamRequest = (data: any): JoinTeamAction => ({
  type: JoinTeamActionTypes.JOINTEAM_REQUEST,
  payload: data
});

export const joinTeamRequestSuccess = (data: any): JoinTeamAction => ({
  type: JoinTeamActionTypes.JOINTEAM_REQUEST_SUCCESS,
  payload: data
});

export const joinTeamRequestError = (error: string): JoinTeamAction => ({
  type: JoinTeamActionTypes.JOINTEAM_REQUEST_ERROR,
  payload: error
});

