import { takeLatest, put, call } from 'redux-saga/effects';
import { CreateClassActionTypes } from '../actions/CreateClassActionTypes';
import { createClassRequestSuccess, createClassRequestError } from '../actions/createClassActions';
import { apiService } from '../../../services/api';

function* createClassRequestSaga(req) {
  try {
    // Call the API using the call effect

    const response = yield call(apiService.createClass, req.payload);

    // Dispatch the success action with the API response
    yield put(createClassRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(createClassRequestError(error.message));
  }
}

export function* watchcreateClassRequest() {
  yield takeLatest(CreateClassActionTypes.SUBMIT_FORM, createClassRequestSaga);
}
