export enum GetResourcesActionTypes {
  GET_RESOURCES = 'getResources/GET_RESOURCES',
  GET_RESOURCES_SUCCESS = 'getResources/GET_RESOURCES_SUCCESS',
  GET_RESOURCES_ERROR = 'getResources/GET_RESOURCES_ERROR'
}

export interface GetResourcesAction {
  type: GetResourcesActionTypes;
  payload?: any;
}
