export enum RegisterActionTypes {
  REGISTER_ACCOUNT = 'registerUser/REGISTER_ACCOUNT',
  REGISTER_ACCOUNT_SUCCESS = 'registerUser/REGISTER_ACCOUNT_SUCCESS',
  REGISTER_ACCOUNT_ERROR = 'registerUser/REGISTER_ACCOUNT_ERROR',
  REGISTER_USER = 'registerUser/REGISTER_USER',
  REGISTER_USER_SUCCESS = 'registerUser/REGISTER_USER_SUCCESS',
  REGISTER_USER_ERROR = 'registerUser/REGISTER_USER_ERROR',
}

export interface RegisterAction {
  type: RegisterActionTypes;
  payload?: any;
}
