export enum AdminRegisterActionTypes {
  ADMIN_REGISTER_USER = 'adminRegisterUser/ADMIN_REGISTER_USER',
  ADMIN_REGISTER_USER_SUCCESS = 'adminRegisterUser/ADMIN_REGISTER_USER_SUCCESS',
  ADMIN_REGISTER_USER_ERROR = 'adminRegisterUser/ADMIN_REGISTER_USER_ERROR',
  ADMIN_LIST_USER = 'adminRegisterUser/ADMIN_LIST_USER',
  ADMIN_LIST_USER_SUCCESS = 'adminRegisterUser/ADMIN_LIST_USER_SUCCESS',
  ADMIN_LIST_USER_ERROR = 'adminRegisterUser/ADMIN_LIST_USER_ERROR',  
}

export interface AdminRegisterAction {
  type: AdminRegisterActionTypes;
  payload?: any;
}
