import { takeLatest, put, call } from 'redux-saga/effects';
import { getClassesActionTypes } from '../actions/getClassesTypes';
import { getClassesRequestSuccess, getClassesRequestError } from '../actions/getClassesActions';
import { apiService } from '../../../services/api';

function* getClassesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getClasses, req.payload);

    // Dispatch the success action with the API response
    yield put(getClassesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getClassesRequestError(error.message));
  }
}

export function* watchgetClassesRequest() {
  yield takeLatest(getClassesActionTypes.GET_CLASSES, getClassesRequestSaga);
}
