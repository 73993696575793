export enum BringCourseToYourLocationActionTypes {
  BRING_COURSE_REQUEST = 'bringCourseToYourLocation/BRING_COURSE_REQUEST',
  BRING_COURSE_REQUEST_SUCCESS = 'bringCourseToYourLocation/BRING_COURSE_REQUEST_SUCCESS',
  BRING_COURSE_REQUEST_ERROR = 'bringCourseToYourLocation/BRING_COURSE_REQUEST_ERROR'
}

export interface BringCourseToYourLocationAction {
  type: BringCourseToYourLocationActionTypes;
  payload?: any;
}
