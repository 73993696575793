// features/login/reducers/loginReducer.ts
import { RegisterActionTypes, RegisterAction } from "../actions/RegisterUserActionTypes";

interface RegisterUserState {
  user: string | null;
  account: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: RegisterUserState = {
  user: null,
  account: null,
  loading: false,
  error: null,
};

const registerUserReducer = (state = initialState, action: RegisterAction): RegisterUserState => {
  switch (action.type) {
    case RegisterActionTypes.REGISTER_ACCOUNT:
      return { ...state, loading: true };
    case RegisterActionTypes.REGISTER_ACCOUNT_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case RegisterActionTypes.REGISTER_ACCOUNT_ERROR:
      return { ...state, loading: false, error: action.payload };    
    case RegisterActionTypes.REGISTER_USER:
      return { ...state, loading: true };
    case RegisterActionTypes.REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case RegisterActionTypes.REGISTER_USER_ERROR:
      return { ...state, loading: false, error: action.payload};
    default:
      return state;
  }
};

export default registerUserReducer;
