import { all, fork } from 'redux-saga/effects';
import { watchLoginUser } from '../features/login/sagas/loginSaga';
import { watchCourseListingRequest } from '../features/courseListing/sagas/courseListingSaga';
import { watchUserMetricsRequest } from '../features/userMetrics/sagas/userMetricsSaga';
import { watchRegisterUser } from '../features/registerUser/sagas/registerUserSaga';
import { watchConfirmRegistration } from '../features/confirmRegistration/sagas/confirmRegistrationSaga';
import { watchForgotPasswordUser } from '../features/forgotPassword/sagas/forgotPasswordSaga';
import { watchVerifyPasswordUser } from '../features/verifyPassword/sagas/verifyPasswordSaga';
import { watchResendCodeUser } from '../features/resendCode/sagas/resendCodeSaga';
import { watchChangePasswordUser } from '../features/changePassword/sagas/changePasswordSaga';
import { watchNewPasswordUser } from '../features/newPassword/sagas/newPasswordSaga';
import { watchHeaderTitleRequest } from '../features/header/sagas';
import { watchcreateClassRequest } from '../features/createClass/sagas/createClassSaga';
import { watchCourseDetailRequest } from '../features/courseDetail/sagas/courseDetailSaga';
import { watchClassModelsRequest } from '../features/classModels/sagas/classModelsSaga';
import { watchAdminRegisterUser } from '../features/adminRegisterUser/sagas/adminRegisterUserSaga';
import { watchProfileRequest } from '../features/getProfile/sagas/profileSaga';
import { watchHomeworkRequest } from '../features/getHomework/sagas/homeworkSaga';
import { watchHomeworkPagesRequest } from '../features/getHomeworkPages/sagas/homeworkPagesSaga';
import { watchHomeworkQuestionsRequest } from '../features/getHomeworkQuestions/sagas/homeworkQuestionsSaga';
import { watchCheckHomeworkQuestionsRequest } from '../features/checkHomeworkQuestions/sagas/homeworkQuestionsSaga';
import { watchStudentsRequest } from '../features/getStudents/sagas/getStudentsSaga';
import { watchMessagesRequest } from '../features/getMessages/sagas/getMessagesSaga';
import { watchsendNotificationsRequest } from '../features/sendNotifications/sagas/sendNotificationsSaga';
import { watchAvailabilityRequest } from '../features/setAvailability/sagas/setAvailabilitySaga';
import { watchScheduleRequest } from '../features/getSchedule/sagas/getScheduleSaga';
import { watchTutorsRequest } from '../features/getTutorRequest/sagas/getTutorSaga';
import { watchJoinTeamRequest } from '../features/getJoinTeam/sagas/getJoinTeamSaga';
import { watchGen3RewardsRequest } from '../features/gen3Rewards/sagas/gen3RewardsSaga';
import { watchclassNotesRequest } from '../features/classNotes/sagas/classNotesSaga';
import { watchuserTasksRequest } from '../features/userTasks/sagas/userTasksSaga';
import { watchmanageCategoriesRequest } from '../features/manageCategories/sagas/manageCategoriesSaga';
import { watchmanageCourseRequest } from '../features/manageCourse/sagas/manageCourseSaga';
import { watchmanageEventsRequest } from '../features/manageEvents/sagas/manageEventsSaga';
import { watchregisterEventsRequest } from '../features/eventRegistration/sagas/eventRegistrationSaga';
import { watchBringCourseToYourLocation } from '../features/bringCourseToYourLocation/sagas/bringCourseToYourLocationSaga';
import { watchFollowTeacher } from '../features/followTeacher/sagas/followTeacherSaga';
import { watchCourseNotification } from '../features/courseNotification/sagas/courseNotificationSaga';
import { watchRequestClass } from '../features/requestClass/sagas/requestClassSaga';
import { watchmakePaymentRequest } from '../features/makePayment/sagas/makePaymentSaga';
import { watchgetClassesRequest } from '../features/getClasses/sagas/getClassesSaga';
import { watchgetEventsRequest } from '../features/getEvents/sagas/getEventsSaga';
import { watchmanageResourcesRequest } from '../features/manageResources/sagas/manageResourcesSaga';
import { watchgetResourcesRequest } from '../features/getResources/sagas/getResourcesSaga';
import { watchmanageLocationRequest } from '../features/manageLocations/sagas/manageLocationSaga';
import { watchgetLocationRequest } from '../features/getLocations/sagas/getLocationSaga';

function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchCourseListingRequest),
    fork(watchUserMetricsRequest),
    fork(watchRegisterUser),
    fork(watchConfirmRegistration),
    fork(watchForgotPasswordUser),
    fork(watchVerifyPasswordUser),
    fork(watchResendCodeUser),
    fork(watchChangePasswordUser),
    fork(watchNewPasswordUser),
    fork(watchHeaderTitleRequest),
    fork(watchcreateClassRequest),
    fork(watchCourseDetailRequest),
    fork(watchClassModelsRequest),
    fork(watchAdminRegisterUser),
    fork(watchProfileRequest),
    fork(watchHomeworkRequest),
    fork(watchHomeworkPagesRequest),
    fork(watchHomeworkQuestionsRequest),
    fork(watchCheckHomeworkQuestionsRequest),
    fork(watchStudentsRequest),
    fork(watchMessagesRequest),
    fork(watchsendNotificationsRequest),
    fork(watchAvailabilityRequest),
    fork(watchScheduleRequest),
    fork(watchTutorsRequest),
    fork(watchJoinTeamRequest),
    fork(watchGen3RewardsRequest),
    fork(watchclassNotesRequest),
    fork(watchuserTasksRequest),
    fork(watchmanageCategoriesRequest),
    fork(watchmanageCourseRequest),
    fork(watchmanageEventsRequest),
    fork(watchregisterEventsRequest),
    fork(watchBringCourseToYourLocation),
    fork(watchFollowTeacher),
    fork(watchCourseNotification),
    fork(watchRequestClass),
    fork(watchmakePaymentRequest),
    fork(watchgetClassesRequest),
    fork(watchgetEventsRequest),
    fork(watchmanageResourcesRequest),
    fork(watchgetResourcesRequest),
    fork(watchmanageLocationRequest),
    fork(watchgetLocationRequest)
  ]);
}

export default rootSaga;
