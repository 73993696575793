// features/login/actions/LoginActionTypes.ts
export enum eventRegistrationActionTypes {
    EVENT_REGISTRATION = 'eventRegistration/EVENT_REGISTRATION',
    EVENT_REGISTRATION_SUCCESS = 'eventRegistration/EVENT_REGISTRATION_SUCCESS',
    EVENT_REGISTRATION_ERROR = 'eventRegistration/EVENT_REGISTRATION_ERROR',
  }
  
  export interface eventRegistrationAction {
    type: eventRegistrationActionTypes;
    payload?: any;
  }
  