import { takeLatest, put, call } from 'redux-saga/effects';
import { GetResourcesActionTypes } from '../actions/getResourcesActionTypes';
import { getResourcesRequestSuccess, getResourcesRequestError } from '../actions/getResourcesActions';
import { apiService } from '../../../services/api';

function* getResourcesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getResources, req.payload);

    // Dispatch the success action with the API response
    yield put(getResourcesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getResourcesRequestError(error.message));
  }
}

export function* watchgetResourcesRequest() {
  yield takeLatest(GetResourcesActionTypes.GET_RESOURCES, getResourcesRequestSaga);
}
