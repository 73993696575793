import { takeLatest, put, call } from 'redux-saga/effects';
import { JoinTeamActionTypes } from '../actions/getJoinTeamActionTypes';
import { joinTeamRequestSuccess, joinTeamRequestError } from '../actions/getJoinTeamActions';
import { apiService } from '../../../services/api';

function* joinTeamRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getJoinTeam, req.payload);

    // Dispatch the success action with the API response
    yield put(joinTeamRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(joinTeamRequestError(error.message));
  }
}

export function* watchJoinTeamRequest() {
  yield takeLatest(JoinTeamActionTypes.JOINTEAM_REQUEST, joinTeamRequestSaga);
}
