import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageLocationActionTypes } from '../actions/manageLocationActionTypes';
import { manageLocationRequestSuccess, manageLocationRequestError } from '../actions/manageLocationActions';
import { apiService } from '../../../services/api';

function* manageLocationRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageLocations, req.payload);

    // Dispatch the success action with the API response
    yield put(manageLocationRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(manageLocationRequestError(error.message));
  }
}

export function* watchmanageLocationRequest() {
  yield takeLatest(ManageLocationActionTypes.MANAGE_LOCATION, manageLocationRequestSaga);
}
