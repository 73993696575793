import { ManageEventsActionTypes, ManageEventsAction } from '../actions/manageEventsActionTypes';

interface ManageEventsState {
  data: string[];
  managing: boolean;
  manage_error: string | null;
}

const initialState: ManageEventsState = {
  data: [],
  managing: false,
  manage_error: null
};

const manageEventsReducer = (state = initialState, action: ManageEventsAction): ManageEventsState => {
  switch (action.type) {
    case ManageEventsActionTypes.MANAGE_EVENTS:
      return { ...state, managing: true };
    case ManageEventsActionTypes.MANAGE_EVENTS_SUCCESS:
      return { ...state, data: action.payload, managing: false, manage_error: null };
    case ManageEventsActionTypes.MANAGE_EVENTS_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
    default:
      return state;
  }
};

export default manageEventsReducer;
