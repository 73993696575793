import { HeaderActionTypes, HeaderAction } from './constants';

export const headerTitleRequest = (data: any): HeaderAction => ({
  type: HeaderActionTypes.HEADER_TITLE,
  payload: data
});

export const headerTitleSuccess = (data: any): HeaderAction => ({
  type: HeaderActionTypes.HEADER_TITLE_REQUEST_SUCCESS,
  payload: data
});

export const headerTitleError = (error: string): HeaderAction => ({
  type: HeaderActionTypes.HEADER_TITLE_REQUEST_ERROR,
  payload: error
});
