// features/registerUser/actions/RegisterActions.ts
import { RegisterActionTypes, RegisterAction } from "./RegisterUserActionTypes";

export const registerUserAccount = (): RegisterAction => ({
  type: RegisterActionTypes.REGISTER_ACCOUNT,
});

export const registerUserAccountSuccess = (data: any): RegisterAction => ({
  type: RegisterActionTypes.REGISTER_ACCOUNT_SUCCESS,
  payload: data,
});

export const registerUserAccountError = (error: string): RegisterAction => ({
  type: RegisterActionTypes.REGISTER_ACCOUNT_ERROR,
});

export const registerUser = (req: any): RegisterAction => ({
  type: RegisterActionTypes.REGISTER_USER,
  payload: req,
});

export const registerUserSuccess = (data: any): RegisterAction => ({
  type: RegisterActionTypes.REGISTER_USER_SUCCESS,
  payload: data,
});

export const registerUserError = (error: string): RegisterAction => ({
  type: RegisterActionTypes.REGISTER_USER_ERROR,
});