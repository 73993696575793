export enum ScheduleActionTypes {
  GET_SCHEDULE_REQUEST = 'getSchedule/GET_SCHEDULE_REQUEST',
  GET_RESCHEDULE_REQUEST = 'getReschedule/GET_RESCHEDULE_REQUEST',
  GET_RESCHEDULE_REQUEST_SUCCESS = 'getSchedule/GET_RESCHEDULE_REQUEST_SUCCESS',
  GET_SCHEDULE_REQUEST_SUCCESS = 'getSchedule/GET_SCHEDULE_REQUEST_SUCCESS',
  GET_RESCHEDULE_REQUEST_ERROR = 'getSchedule/GET_RESCHEDULE_REQUEST_ERROR',
  GET_SCHEDULE_REQUEST_ERROR = 'getSchedule/GET_SCHEDULE_REQUEST_ERROR',
  UPDATE_SCHEDULE_REQUEST = 'getSchedule/UPDATE_SCHEDULE_REQUEST',
  UPDATE_SCHEDULE_REQUEST_SUCCESS = 'getSchedule/UPDATE_SCHEDULE_REQUEST_SUCCESS',
  UPDATE_SCHEDULE_REQUEST_ERROR = 'getSchedule/UPDATE_SCHEDULE_REQUEST_ERROR',
  DELETE_SCHEDULE_REQUEST = 'getSchedule/DELETE_SCHEDULE_REQUEST',
  DELETE_SCHEDULE_REQUEST_SUCCESS = 'getSchedule/DELETE_SCHEDULE_REQUEST_SUCCESS',
  DELETE_SCHEDULE_REQUEST_ERROR = 'getSchedule/DELETE_SCHEDULE_REQUEST_ERROR'
}

export interface ScheduleAction {
  type: ScheduleActionTypes;
  payload?: any;
}
