// features/newPassword/actions/newPasswordActions.ts
import { NewPasswordActionTypes, NewPasswordAction } from "./NewPasswordActionTypes";

export const newPasswordUser = (req: any): NewPasswordAction => ({
  type: NewPasswordActionTypes.NEW_PASSWORD_USER,
  payload: req,
});

export const newPasswordUserSuccess = (data: any): NewPasswordAction => ({
  type: NewPasswordActionTypes.NEW_PASSWORD_USER_SUCCESS,
  payload: data,
});

export const newPasswordUserError = (error: string): NewPasswordAction => ({
  type: NewPasswordActionTypes.NEW_PASSWORD_USER_ERROR,
});