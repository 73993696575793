export enum ManageResourcesActionTypes {
  MANAGE_RESOURCES = 'manageResources/MANAGE_RESOURCES',
  MANAGE_RESOURCES_SUCCESS = 'manageResources/MANAGE_RESOURCES_SUCCESS',
  MANAGE_RESOURCES_ERROR = 'manageResources/MANAGE_RESOURCES_ERROR'
}

export interface ManageResourcesAction {
  type: ManageResourcesActionTypes;
  payload?: any;
}
