export enum CourseListingActionTypes {
  COURSE_LISTING_REQUEST = 'courseListing/COURSE_LISTING_REQUEST',
  COURSE_LISTING_REQUEST_SUCCESS = 'courseListing/COURSE_LISTING_REQUEST_SUCCESS',
  COURSE_LISTING_REQUEST_ERROR = 'courseListing/COURSE_LISTING_REQUEST_ERROR',
  COURSE_LISTING_REQUEST_TEACHERS = 'courseListing/COURSE_LISTING_REQUEST_TEACHERS',
  COURSE_LISTING_REQUEST_TEACHERS_SUCCESS = 'courseListing/COURSE_LISTING_REQUEST_TEACHERS_SUCCESS',
  COURSE_LISTING_REQUEST_TEACHERS_ERROR = 'courseListing/COURSE_LISTING_REQUEST_TEACHERS_ERROR'
}
export interface CourseListingAction {
  type: CourseListingActionTypes;
  payload?: any;
}
