import { CourseNotificationAction, CourseNotificationActionTypes } from '../actions/courseNotificationActionTypes';

interface CourseNotificationState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: CourseNotificationState = {
  data: {},
  loading: false,
  error: null
};

const courseNotificationReducer = (state = initialState, action: CourseNotificationAction): CourseNotificationState => {
  switch (action.type) {
    case CourseNotificationActionTypes.COURSE_NOTIFICATION_REQUEST:
      return { ...state, loading: true, data: action.payload };
    case CourseNotificationActionTypes.COURSE_NOTIFICATION_REQUEST_SUCCESS:
      return { ...state, loading: false, error: null };
    case CourseNotificationActionTypes.COURSE_NOTIFICATION_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default courseNotificationReducer;
