// features/login/actions/loginActions.ts
import { LoginActionTypes, LoginAction } from './LoginActionTypes';

export const loginAccount = (): LoginAction => ({
  type: LoginActionTypes.LOGIN_ACCOUNT
});

export const loginAccountSuccess = (data: any): LoginAction => ({
  type: LoginActionTypes.LOGIN_ACCOUNT_SUCCESS,
  payload: data
});

export const loginAccountError = (error: string): LoginAction => ({
  type: LoginActionTypes.LOGIN_ACCOUNT_ERROR
});

export const loginUser = (req: any): LoginAction => ({
  type: LoginActionTypes.LOGIN_USER,
  payload: req
});

export const loginUserSuccess = (data: any): LoginAction => ({
  type: LoginActionTypes.LOGIN_USER_SUCCESS,
  payload: data
});

export const loginUserError = (error: string): LoginAction => ({
  type: LoginActionTypes.LOGIN_USER_ERROR
});
