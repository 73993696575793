export enum SendNotificationsActionTypes {
  SEND_NOTIFICATIONS = 'sendNotifications/SEND_NOTIFICATIONS',
  SEND_NOTIFICATIONS_SUCCESS = 'sendNotifications/SEND_NOTIFICATIONS_SUCCESS',
  SEND_NOTIFICATIONS_ERROR = 'sendNotifications/SEND_NOTIFICATIONS_ERROR',
  RESET = 'sendNotifications/RESET'
}

export interface SendNotificationsAction {
  type: SendNotificationsActionTypes;
  payload?: any;
}
