import { takeLatest, put, call } from 'redux-saga/effects';
import { ForgotPasswordActionTypes } from '../actions/ForgotPasswordActionTypes';
import {
  forgotPasswordAccountSuccess,
  forgotPasswordAccountError,
  forgotPasswordUserSuccess,
  forgotPasswordUserError
} from '../actions/forgotPasswordActions';
import { apiService } from '../../../services/api'; // Update the path as needed

const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-1' });
const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

function* forgotPasswordUserSaga(action) {
  try {
    const req = action.payload;

    // Simulate an asynchronous API call
    const response = yield call(apiService.ForgotPwd, req);
    yield put(forgotPasswordAccountSuccess(response));
    const client_id = response.client_id;
    const user_name = response.username;
    const forgotPasswordParams = {
      ClientId: client_id,
      Username: user_name
    };
    localStorage.setItem('ClientId', client_id);
    localStorage.setItem('user_name', user_name);
    const authPromise = new Promise(async (resolve, reject) => {
      await cognitoIdentityServiceProvider.forgotPassword(forgotPasswordParams, (err, result: any) => {
        if (err) {
          console.log('Password Request failed:', err);
          reject(err);
          localStorage.setItem('pwd_status', 'false');
        } else {
          const cognitoUser = result.user;

          localStorage.setItem('pwd_status', 'true');
          resolve({ cognitoUser });
        }
      });
    });
    try {
      const authResult = yield authPromise;
      yield put(forgotPasswordUserSuccess(authResult));
    } catch (error: any) {
      yield put(forgotPasswordUserError(error));
    }
  } catch (error: any) {
    yield put(forgotPasswordAccountError(error));
  }
}

export function* watchForgotPasswordUser() {
  yield takeLatest(ForgotPasswordActionTypes.FORGOT_PASSWORD_USER, forgotPasswordUserSaga);
}
