import { AvailabilityActionTypes, AvailabilityAction } from '../actions/setAvailabilityActionTypes';

interface AvailabilityState {
  availability_data: string[];
  loading_availability: boolean;
  loaded_availability: boolean;
  data: string[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

const initialState: AvailabilityState = {
  availability_data: [],
  loading_availability: false,
  loaded_availability: false,
  data: [],
  loading: false,
  loaded: false,
  error: null
};

const availabilityReducer = (state = initialState, action: AvailabilityAction): AvailabilityState => {
  switch (action.type) {
    case AvailabilityActionTypes.GET_AVAILABILITY_REQUEST:
      return { ...state, loading_availability: true };
    case AvailabilityActionTypes.GET_AVAILABILITY_REQUEST_SUCCESS:
      return {
        ...state,
        availability_data: action.payload,
        loading_availability: false,
        loaded_availability: true,
        error: null
      };
    case AvailabilityActionTypes.GET_AVAILABILITY_REQUEST_ERROR:
      return { ...state, loading_availability: false, loaded_availability: false, error: action.payload };
    case AvailabilityActionTypes.SET_AVAILABILITY_REQUEST:
      return { ...state, loading: true };
    case AvailabilityActionTypes.SET_AVAILABILITY_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case AvailabilityActionTypes.SET_AVAILABILITY_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case AvailabilityActionTypes.UPDATE_AVAILABILITY_REQUEST:
      return { ...state, loading: true };
    case AvailabilityActionTypes.UPDATE_AVAILABILITY_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case AvailabilityActionTypes.UPDATE_AVAILABILITY_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST:
      return { ...state, loading: true };
    case AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default availabilityReducer;
