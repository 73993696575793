export enum ClassModelsActionTypes {
    CLASS_MODELS_REQUEST = 'classModels/CLASS_MODELS_REQUEST',
    CLASS_MODELS_REQUEST_SUCCESS = 'classModels/CLASS_MODELS_REQUEST_SUCCESS',
    CLASS_MODELS_REQUEST_ERROR = 'classModels/CLASS_MODELS_REQUEST_ERROR',
  }
  
  export interface ClassModelsAction {
    type: ClassModelsActionTypes;
    payload?: any;
  }
  