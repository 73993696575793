// features/login/reducers/loginReducer.ts
import { ConfirmRegistrationActionTypes, ConfirmRegistrationAction } from "../actions/ConfirmRegistrationActionTypes";

interface RegisterUserState {
  user: string | null;
  account: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: RegisterUserState = {
  user: null,
  account: null,
  loading: false,
  error: null,
};

const confirmRegistrationReducer = (state = initialState, action: ConfirmRegistrationAction): RegisterUserState => {
  switch (action.type) {
    case ConfirmRegistrationActionTypes.CONFIRM_REGISTRATION:
      return { ...state, loading: true };
    case ConfirmRegistrationActionTypes.CONFIRM_REGISTRATION_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case ConfirmRegistrationActionTypes.CONFIRM_REGISTRATION_ERROR:
      return { ...state, loading: false, error: action.payload };    
    default:
      return state;
  }
};

export default confirmRegistrationReducer;
