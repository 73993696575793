
export enum ChangePasswordActionTypes {
  CHANGE_PASSWORD_USER = 'changePassword/CHANGE_PASSWORD_USER',
  CHANGE_PASSWORD_USER_SUCCESS = 'changePassword/CHANGE_PASSWORD_USER_SUCCESS',
  CHANGE_PASSWORD_USER_ERROR = 'changePassword/CHANGE_PASSWORD_USER_ERROR',
}

export interface ChangePasswordAction {
  type: ChangePasswordActionTypes;
  payload?: any;
}
