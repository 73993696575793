// features/createClass/actions/createClassActions.ts
import { ManageCategoriesActionTypes, ManageCategoriesAction } from './manageCategoriesActionTypes';

export const getCategoriesRequest = (data: any): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.GET_CATEGORIES,
  payload: data
});

export const getCategoriesRequestSuccess = (data: any): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.GET_CATEGORIES_SUCCESS,
  payload: data
});

export const getCategoriesRequestError = (error: string): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.GET_CATEGORIES_ERROR,
  payload: error
});

export const createCategoriesRequest = (data: any): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.CREATE_CATEGORIES,
  payload: data
});

export const createCategoriesRequestSuccess = (data: any): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.CREATE_CATEGORIES_SUCCESS,
  payload: data
});

export const createCategoriesRequestError = (error: string): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.CREATE_CATEGORIES_ERROR,
  payload: error
});

export const updateCategoriesRequest = (data: any): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.UPDATE_CATEGORIES,
  payload: data
});

export const updateCategoriesRequestSuccess = (data: any): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.UPDATE_CATEGORIES_SUCCESS,
  payload: data
});

export const updateCategoriesRequestError = (error: string): ManageCategoriesAction => ({
  type: ManageCategoriesActionTypes.UPDATE_CATEGORIES_ERROR,
  payload: error
});
