import axios from '../../Utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../configureStore';
import type { PayloadAction } from '@reduxjs/toolkit';

const ENV = process.env.REACT_APP_LANE;
const BASE_URL = `https://${process.env.REACT_APP_BASE_URL}/${ENV}`;

interface StateType {
  Course: any[];
  CourseContent: number;
  courseSearch: string;
}

const initialState = {
  Course: [],
  CourseContent: 1,
  courseSearch: ''
};

export const CourseSlice = createSlice({
  name: 'Course',
  initialState,
  reducers: {
    getCourse: (state, action) => {
      state.Course = action.payload;
    },
    SearchCourse: (state, action) => {
      state.courseSearch = action.payload;
    },
    SelectCourse: (state, action) => {
      state.CourseContent = action.payload;
    },

    DeleteCourse(state: StateType, action) {
      const index = state.Course.findIndex((course) => course.id === action.payload);
      state.Course.splice(index, 1);
    },

    UpdateCourse: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        state.Course = state.Course.map((course) =>
          course.id === action.payload.id ? { ...course, [action.payload.field]: action.payload.value } : course
        );
      },
      prepare: (id, field, value) => {
        return {
          payload: { id, field, value }
        };
      }
    },

    addCourse: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        state.Course.push(action.payload);
      },
      prepare: (id, title, color) => {
        return { payload: { id, title, color, datef: new Date().toDateString(), deleted: false } };
      }
    }
  }
});

export const { SearchCourse, getCourse, SelectCourse, DeleteCourse, UpdateCourse, addCourse } = CourseSlice.actions;

export const fetchCourse = (req) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/getCourseList`, { params: req });
    dispatch(getCourse(response.data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export default CourseSlice.reducer;
