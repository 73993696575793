import axios from '../../Utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../configureStore';
import type { PayloadAction } from '@reduxjs/toolkit';

const ENV = process.env.REACT_APP_LANE;
const BASE_URL = `https://${process.env.REACT_APP_BASE_URL}/${ENV}`;

interface StateType {
  students: any[];
  studentsDetail: any[];
  studentsSearch: string;
}

const initialState = {
  students: [],
  studentsDetail: [],
  studentsSearch: ''
};

export const StudentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    getStudents: (state, action) => {
      state.students = action.payload;
    },
    SearchStudents: (state, action) => {
      state.studentsSearch = action.payload;
    },
    SelectStudents: (state, action) => {
      state.studentsDetail = action.payload;
    },

    DeleteStudents(state: StateType, action) {
      const index = state.students.findIndex((students) => students.id === action.payload);
      state.students.splice(index, 1);
    },

    UpdateStudents: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        state.students = state.students.map((students) =>
          students.id === action.payload.id ? { ...students, [action.payload.field]: action.payload.value } : students
        );
      },
      prepare: (id, field, value) => {
        return {
          payload: { id, field, value }
        };
      }
    },

    addStudents: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        state.students.push(action.payload);
      },
      prepare: (id, title, color) => {
        return { payload: { id, title, color, datef: new Date().toDateString(), deleted: false } };
      }
    }
  }
});

export const { SearchStudents, getStudents, SelectStudents, DeleteStudents, UpdateStudents, addStudents } =
  StudentsSlice.actions;

export const fetchStudents = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}/getStudentsList`);
    dispatch(getStudents(response.data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export default StudentsSlice.reducer;
