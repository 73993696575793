import { ProfileActionTypes, ProfileAction } from './ProfileActionTypes';

export const profileRequest = (data: any): ProfileAction => ({
  type: ProfileActionTypes.PROFILE_REQUEST,
  payload: data
});

export const profileRequestSuccess = (data: any): ProfileAction => ({
  type: ProfileActionTypes.PROFILE_REQUEST_SUCCESS,
  payload: data
});

export const profileRequestError = (error: string): ProfileAction => ({
  type: ProfileActionTypes.PROFILE_REQUEST_ERROR,
  payload: error
});

export const userProfileRequest = (data: any): ProfileAction => ({
  type: ProfileActionTypes.USER_PROFILE_REQUEST,
  payload: data
});

export const userProfileRequestSuccess = (data: any): ProfileAction => ({
  type: ProfileActionTypes.USER_PROFILE_REQUEST_SUCCESS,
  payload: data
});

export const userProfileRequestError = (error: string): ProfileAction => ({
  type: ProfileActionTypes.USER_PROFILE_REQUEST_ERROR,
  payload: error
});

export const updateProfileRequest = (data: any): ProfileAction => ({
  type: ProfileActionTypes.UPDATE_PROFILE_REQUEST,
  payload: data
});

export const updateProfileRequestSuccess = (data: any): ProfileAction => ({
  type: ProfileActionTypes.UPDATE_PROFILE_REQUEST_SUCCESS,
  payload: data
});

export const updateProfileRequestError = (error: string): ProfileAction => ({
  type: ProfileActionTypes.UPDATE_PROFILE_REQUEST_ERROR,
  payload: error
});

export const deleteProfileRequest = (data: any): ProfileAction => ({
  type: ProfileActionTypes.DELETE_PROFILE_REQUEST,
  payload: data
});

export const deleteProfileRequestSuccess = (data: any): ProfileAction => ({
  type: ProfileActionTypes.DELETE_PROFILE_REQUEST_SUCCESS,
  payload: data
});

export const deleteProfileRequestError = (error: string): ProfileAction => ({
  type: ProfileActionTypes.DELETE_PROFILE_REQUEST_ERROR,
  payload: error
});
