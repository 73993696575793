import { UserMetricsAction, UserMetricsActionTypes } from './userMetricsActionTypes';

export const userMetricsRequest = (data: any): UserMetricsAction => ({
  type: UserMetricsActionTypes.USER_METRICS_REQUEST,
  payload: data
});

export const userMetricsRequestSuccess = (data: any): UserMetricsAction => ({
  type: UserMetricsActionTypes.USER_METRICS_REQUEST_SUCCESS,
  payload: data
});

export const userMetricsRequestError = (error: string): UserMetricsAction => ({
  type: UserMetricsActionTypes.USER_METRICS_REQUEST_ERROR,
  payload: error
});
