import { GetResourcesActionTypes, GetResourcesAction } from './getResourcesActionTypes';

export const getResourcesRequest = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES,
  payload: data
});

export const getResourcesRequestSuccess = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES_SUCCESS,
  payload: data
});

export const getResourcesRequestError = (error: string): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES_ERROR,
  payload: error
});
