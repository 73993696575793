import { ClassNotesActionTypes, ClassNotesAction } from '../actions/classNotesActionTypes';

interface ClassNotesState {
  data: string[];
  loading: boolean;
  error: string | null;
  updating: boolean;
  deleting: boolean;
}

const initialState: ClassNotesState = {
  data: [],
  loading: false,
  error: null,
  updating: false,
  deleting: false
};

const classNotesReducer = (state = initialState, action: ClassNotesAction): ClassNotesState => {
  switch (action.type) {
    case ClassNotesActionTypes.CLASS_NOTES_REQUEST:
      return { ...state, loading: true };
    case ClassNotesActionTypes.CLASS_NOTES_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case ClassNotesActionTypes.CLASS_NOTES_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ClassNotesActionTypes.CLASS_NOTES_UPDATE_REQUEST:
      return { ...state, updating: true };
    case ClassNotesActionTypes.CLASS_NOTES_UPDATE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, updating: false, error: null };
    case ClassNotesActionTypes.CLASS_NOTES_UPDATE_REQUEST_ERROR:
      return { ...state, updating: false, error: action.payload };

    case ClassNotesActionTypes.CLASS_NOTES_DELETE_REQUEST:
      return { ...state, deleting: true };
    case ClassNotesActionTypes.CLASS_NOTES_DELETE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, deleting: false, error: null };
    case ClassNotesActionTypes.CLASS_NOTES_DELETE_REQUEST_ERROR:
      return { ...state, deleting: false, error: action.payload };
    default:
      return state;
  }
};

export default classNotesReducer;
