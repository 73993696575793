// features/manageLocation/actions/manageLocationActions.ts
import { M } from '@fullcalendar/core/internal-common';
import { ManageLocationActionTypes, ManageLocationAction } from './manageLocationActionTypes';

export const manageLocationRequest = (data: any): ManageLocationAction => ({
  type: ManageLocationActionTypes.MANAGE_LOCATION,
  payload: data
});

export const manageLocationRequestSuccess = (data: any): ManageLocationAction => ({
  type: ManageLocationActionTypes.MANAGE_LOCATION_SUCCESS,
  payload: data
});

export const manageLocationRequestError = (error: string): ManageLocationAction => ({
  type: ManageLocationActionTypes.MANAGE_LOCATION_ERROR,
  payload: error
});
export const createLocationRequest=(data:any):ManageLocationAction =>({
  type:ManageLocationActionTypes.CREATE_LOCATION,
  payload:data
});
export const createLocationRequestSuccess=(data:any):ManageLocationAction=> ({
 type:ManageLocationActionTypes.CREATE_LOCATION_SUCCESS,
 payload:data
});
  
export const createLocationRequestError= (error:string): ManageLocationAction=>({
  type:ManageLocationActionTypes.CREATE_LOCATION_ERROR,
  payload:error
});
export const updateLocationRequest=(data:any):ManageLocationAction=>({
  type:ManageLocationActionTypes.UPDATE_LOCATION,
  payload:data
});
export const updateLocationRequestSuccess=(data:any):ManageLocationAction=>({
  type:ManageLocationActionTypes.UPDATE_LOCATION_SUCCESS,
  payload:data
});
export const updateLocationRequestError=(error:string):ManageLocationAction=>({
  type:ManageLocationActionTypes.UPDATE_LOCATION_ERROR,
  payload:error
});
export const deleteLocationRequest=(data:any):ManageLocationAction=>({
  type:ManageLocationActionTypes.DELETE_LOCATION,
  payload:data
});
export const deleteLocationRequestSuccess=(data:any):ManageLocationAction=>({
  type:ManageLocationActionTypes.UPDATE_LOCATION_SUCCESS,
  payload:data
});
export const deleteLocationRequestError=(error:string):ManageLocationAction=>({
  type:ManageLocationActionTypes.UPDATE_LOCATION_ERROR,
  payload:error
});
