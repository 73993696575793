// features/registerUser/actions/RegisterActions.ts
import { AdminRegisterActionTypes, AdminRegisterAction } from "./AdminRegisterUserActionTypes";

export const adminRegisterUser = (req: any): AdminRegisterAction => ({
  type: AdminRegisterActionTypes.ADMIN_REGISTER_USER,
  payload: req,
});

export const adminRegisterUserSuccess = (data: any): AdminRegisterAction => ({
  type: AdminRegisterActionTypes.ADMIN_REGISTER_USER_SUCCESS,
  payload: data,
});

export const adminRegisterUserError = (error: string): AdminRegisterAction => ({
  type: AdminRegisterActionTypes.ADMIN_REGISTER_USER_ERROR,
  payload: error,
});

export const adminListUser = (): AdminRegisterAction => ({
  type: AdminRegisterActionTypes.ADMIN_LIST_USER,
});

export const adminListUserSuccess = (data: any): AdminRegisterAction => ({
  type: AdminRegisterActionTypes.ADMIN_LIST_USER_SUCCESS,
  payload: data,
});

export const adminListUserError = (error: string): AdminRegisterAction => ({
  type: AdminRegisterActionTypes.ADMIN_LIST_USER_ERROR,
});