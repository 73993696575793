// features/createClass/actions/createClassActions.ts
import { ManageEventsActionTypes, ManageEventsAction } from './manageEventsActionTypes';

export const manageEventsRequest = (data: any): ManageEventsAction => ({
  type: ManageEventsActionTypes.MANAGE_EVENTS,
  payload: data
});

export const manageEventsRequestSuccess = (data: any): ManageEventsAction => ({
  type: ManageEventsActionTypes.MANAGE_EVENTS_SUCCESS,
  payload: data
});

export const manageEventsRequestError = (error: string): ManageEventsAction => ({
  type: ManageEventsActionTypes.MANAGE_EVENTS_ERROR,
  payload: error
});
