import axios from '../../Utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppState } from '../configureStore';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../configureStore';

const ENV = 'test';

interface StateType {
  notes: any[];
  notesContent: number;
  noteSearch: string;
}

const initialState = {
  notes: [],
  notesContent: 1,
  noteSearch: ''
};

export const NotesSlice: any = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    getNotes: (state, action) => {
      state.notes = action.payload;
    },
    SearchNotes: (state, action) => {
      state.noteSearch = action.payload;
    },
    SelectNote: (state, action) => {
      state.notesContent = action.payload;
    },

    DeleteNote(state: StateType, action) {
      const index = state.notes.findIndex((note) => note.id === action.payload);
      state.notes.splice(index, 1);
    },

    UpdateNote: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        const notes_index = action.payload.enteredNotes.id;
        const update_input = action.payload.enteredNotes;
        const updated_data = {
          id: {
            id: notes_index,
            notes: update_input.notes,
            color: update_input.color
          },
          datef: action.payload.datef,
          deleted: action.payload.deleted
        };
        state.notes[notes_index] = updated_data;
      },
      prepare: (enteredNotes) => {
        return {
          payload: { enteredNotes, datef: new Date().toDateString(), deleted: false }
        };
      }
    },

    addNote: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        state.notes.push(action.payload); //we don't need to store the notes - so this may not be needed.
      },
      prepare: (id, enteredNotes, color) => {
        return { payload: { id, enteredNotes, color, datef: new Date().toDateString(), deleted: false } };
      }
    }
  }
});

export const { SearchNotes, getNotes, SelectNote, DeleteNote, UpdateNote, addNote } = NotesSlice.actions;

export const fetchNotes: any = (req) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post(`${req?.user_api}/${ENV}/GetNotes`, {
        headers: { Authorization: `Bearer ${req?.idToken}` },
        data: { class_id: req?.class_id }
      });
      dispatch(getNotes(response.data));
    } catch (err: any) {
      console.error('Failed to fetch notes:', err);
      throw new Error(err.message || 'Unknown error');
    }
  };
};

export const addNotes: any =
  (notesData: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const response: any = await axios.post<{ notesData: any }>(`${notesData.user_api}/GetNotes`, notesData);
    dispatch(NotesSlice.actions.addNote(response.data.message));
  };

export const updateNotes: any =
  (updatedNotesData: any): AppThunk =>
  async (dispatch) => {
    const response: any = await axios.post<{ updatedNotesData: any }>(
      `${updatedNotesData.user_api}/UpdateNotes`,
      updatedNotesData
    );
    dispatch(NotesSlice.actions.UpdateNote(response.data.message));
  };

export default NotesSlice.reducer;
