import { UserMetricsAction, UserMetricsActionTypes } from '../actions/userMetricsActionTypes';

interface userMetricsState {
  data: string[];
  loading: boolean;
  error: string | null;
}

const initialState: userMetricsState = {
  data: [],
  loading: false,
  error: null,
};

const userMetricsReducer = (state = initialState, action: UserMetricsAction): userMetricsState => {
  switch (action.type) {
    case UserMetricsActionTypes.USER_METRICS_REQUEST:
      return { ...state, loading: true };
    case UserMetricsActionTypes.USER_METRICS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case UserMetricsActionTypes.USER_METRICS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default userMetricsReducer;
