import { RequestClassAction, RequestClassActionTypes } from './requestClassActionTypes';

export const requestClass = (data: any): RequestClassAction => ({
  type: RequestClassActionTypes.REQUEST_CLASS_REQUEST,
  payload: data
});

export const requestClassSuccess = (data: any): RequestClassAction => ({
  type: RequestClassActionTypes.REQUEST_CLASS_REQUEST_SUCCESS,
  payload: data
});

export const requestClassError = (error: string): RequestClassAction => ({
  type: RequestClassActionTypes.REQUEST_CLASS_REQUEST_ERROR,
  payload: error
});
