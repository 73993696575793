// features/createClass/actions/createClassActions.ts
import { getClassesActionTypes, getClassesAction } from './getClassesTypes';

export const getClassesRequest = (data: any): getClassesAction => ({
  type: getClassesActionTypes.GET_CLASSES,
  payload: data
});

export const getClassesRequestSuccess = (data: any): getClassesAction => ({
  type: getClassesActionTypes.GET_CLASSES_SUCCESS,
  payload: data
});

export const getClassesRequestError = (error: string): getClassesAction => ({
  type: getClassesActionTypes.GET_CLASSES_ERROR,
  payload: error
});
