export enum getEventsActionTypes {
  GET_EVENTS = 'getEvents/GET_EVENTS',
  GET_EVENTS_SUCCESS = 'getEvents/GET_EVENTS_SUCCESS',
  GET_EVENTS_ERROR = 'getEvents/GET_EVENTS_ERROR'
}

export interface getEventsAction {
  type: getEventsActionTypes;
  payload?: any;
}
