// features/login/actions/LoginActionTypes.ts
export enum ConfirmRegistrationActionTypes {
  CONFIRM_REGISTRATION = 'confirmRegistration/CONFIRM_REGISTRATION',
  CONFIRM_REGISTRATION_SUCCESS = 'confirmRegistration/CONFIRM_REGISTRATION_SUCCESS',
  CONFIRM_REGISTRATION_ERROR = 'confirmRegistration/CONFIRM_REGISTRATION_ERROR',
}

export interface ConfirmRegistrationAction {
  type: ConfirmRegistrationActionTypes;
  payload?: any;
}
