import { takeLatest, put, call } from 'redux-saga/effects';
import { UserMetricsActionTypes } from '../actions/userMetricsActionTypes';
import { userMetricsRequestSuccess, userMetricsRequestError } from '../actions/userMetricsActions';
import { apiService } from '../../../services/api';

function* userMetricsRequestSaga(action) {
  try {
    // Call the API using the call effect
    const idToken = action.payload;
    const response = yield call(apiService.UserMetrics, idToken);

    // Dispatch the success action with the API response
    yield put(userMetricsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(userMetricsRequestError(error.message));
  }
}

export function* watchUserMetricsRequest() {
  yield takeLatest(UserMetricsActionTypes.USER_METRICS_REQUEST, userMetricsRequestSaga);
}
