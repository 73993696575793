import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageCourseActionTypes } from '../actions/manageCourseActionTypes';
import {
  getCourseRequestSuccess,
  getCourseRequestError,
  createCourseRequestSuccess,
  createCourseRequestError,
  updateCourseRequestSuccess,
  updateCourseRequestError
} from '../actions/manageCourseActions';
import { apiService } from '../../../services/api';

function* getCourseRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageCourse, req.payload);

    // Dispatch the success action with the API response
    yield put(getCourseRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getCourseRequestError(error.message));
  }
}

function* createCourseRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageCourse, req.payload);

    // Dispatch the success action with the API response
    yield put(createCourseRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(createCourseRequestError(error.message));
  }
}

function* updateCourseRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageCourse, req.payload);

    // Dispatch the success action with the API response
    yield put(updateCourseRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateCourseRequestError(error.message));
  }
}

export function* watchmanageCourseRequest() {
  yield takeLatest(ManageCourseActionTypes.GET_COURSE, getCourseRequestSaga);
  yield takeLatest(ManageCourseActionTypes.CREATE_COURSE, createCourseRequestSaga);
  yield takeLatest(ManageCourseActionTypes.UPDATE_COURSE, updateCourseRequestSaga);
}
