import { StudentsActionTypes, StudentsAction } from '../actions/GetStudentsActionTypes';

interface StudentsState {
  data: string[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

const initialState: StudentsState = {
  data: [],
  loading: false,
  loaded: false,
  error: null
};

const studentsReducer = (state = initialState, action: StudentsAction): StudentsState => {
  switch (action.type) {
    case StudentsActionTypes.STUDENTS_REQUEST:
      return { ...state, loading: true };
    case StudentsActionTypes.STUDENTS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case StudentsActionTypes.STUDENTS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case StudentsActionTypes.UPDATE_STUDENTS_REQUEST:
      return { ...state, loading: true };
    case StudentsActionTypes.UPDATE_STUDENTS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case StudentsActionTypes.UPDATE_STUDENTS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case StudentsActionTypes.DELETE_STUDENTS_REQUEST:
      return { ...state, loading: true };
    case StudentsActionTypes.DELETE_STUDENTS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case StudentsActionTypes.DELETE_STUDENTS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default studentsReducer;
