// features/eventRegistration/actions/eventRegistrationActions.tsx
import { eventRegistrationActionTypes, eventRegistrationAction } from "./eventRegistrationActionTypes";

export const eventRegistration = (data: any): eventRegistrationAction => ({
  type: eventRegistrationActionTypes.EVENT_REGISTRATION,
  payload: data,
});

export const eventRegistrationSuccess = (data: any): eventRegistrationAction => ({
  type: eventRegistrationActionTypes.EVENT_REGISTRATION_SUCCESS,
  payload: data,
});

export const eventRegistrationError = (error: string): eventRegistrationAction => ({
  type: eventRegistrationActionTypes.EVENT_REGISTRATION_ERROR,
});