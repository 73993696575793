import { takeLatest, put, call } from 'redux-saga/effects';
import { eventRegistrationActionTypes } from '../actions/eventRegistrationActionTypes';
import { eventRegistrationSuccess, eventRegistrationError } from '../actions/eventRegistrationActions';
import { apiService } from '../../../services/api';

function* registerEventSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.EventRegistration, req.payload);

    // Dispatch the success action with the API response
    yield put(eventRegistrationSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(eventRegistrationError(error.message));
  }
}

export function* watchregisterEventsRequest() {
  yield takeLatest(eventRegistrationActionTypes.EVENT_REGISTRATION, registerEventSaga);
}
