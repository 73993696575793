import { SendNotificationsActionTypes, SendNotificationsAction } from '../actions/SendNotificationsActionTypes';

interface SendNotificationsState {
  data: string[];
  loading: boolean;
  error: string | null;
  posted: boolean;
}

const initialState: SendNotificationsState = {
  data: [],
  loading: false,
  error: null,
  posted: false
};

const sendNotificationsReducer = (state = initialState, action: SendNotificationsAction): SendNotificationsState => {
  switch (action.type) {
    case SendNotificationsActionTypes.SEND_NOTIFICATIONS:
      return { ...state, loading: true, posted: false };
    case SendNotificationsActionTypes.SEND_NOTIFICATIONS_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null, posted: true };
    case SendNotificationsActionTypes.SEND_NOTIFICATIONS_ERROR:
      return { ...state, loading: false, error: action.payload, posted: false };
    case SendNotificationsActionTypes.RESET:
      return { ...state, data: [], loading: false, error: null, posted: false };
    default:
      return state;
  }
};

export default sendNotificationsReducer;
