import { takeLatest, put, call } from 'redux-saga/effects';
import { Gen3RewardsActionTypes } from '../actions/gen3RewardsActionTypes';
import { gen3RewardsRequestSuccess, gen3RewardsRequestError } from '../actions/gen3RewardsActions';
import { apiService } from '../../../services/api';

function* gen3RewardsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.gen3Rewards, req.payload);

    // Dispatch the success action with the API response
    yield put(gen3RewardsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(gen3RewardsRequestError(error.message));
  }
}

export function* watchGen3RewardsRequest() {
  yield takeLatest(Gen3RewardsActionTypes.GEN3_REWARDS_REQUEST, gen3RewardsRequestSaga);
}
