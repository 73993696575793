import { takeLatest, put, call } from 'redux-saga/effects';
import { UserTasksActionTypes } from '../actions/userTasksActionTypes';
import {
  userTasksRequestSuccess,
  userTasksRequestError,
  userTasksUpdateRequestSuccess,
  userTasksDeleteRequestSuccess,
  userTasksDeleteRequestError,
  userTasksUpdateRequestError
} from '../actions/userTasksActions';
import { apiService } from '../../../services/api';

function* userTasksRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.userTasks, req.payload);

    // Dispatch the success action with the API response
    yield put(userTasksRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(userTasksRequestError(error.message));
  }
}

function* userTasksUpdateRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.userTasks, req.payload);

    // Dispatch the success action with the API response
    yield put(userTasksUpdateRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(userTasksUpdateRequestError(error.message));
  }
}

function* userTasksDeleteRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.userTasks, req.payload);

    // Dispatch the success action with the API response
    yield put(userTasksDeleteRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(userTasksDeleteRequestError(error.message));
  }
}

export function* watchuserTasksRequest() {
  yield takeLatest(UserTasksActionTypes.USER_TASKS_REQUEST, userTasksRequestSaga);
  yield takeLatest(UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST, userTasksUpdateRequestSaga);
  yield takeLatest(UserTasksActionTypes.USER_TASKS_DELETE_REQUEST, userTasksDeleteRequestSaga);
}
