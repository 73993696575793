import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor, AppDispatch } from './store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import Spinner from './views/spinner/Spinner';
import { BrowserRouter } from 'react-router-dom';
import { SidebarProvider } from './context/SidebarContext';
import { AuthProvider } from './context/AuthContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const notification_url = 'https://fmu8s48je2.execute-api.us-east-1.amazonaws.com/test/Notifications';
const applicationServerPublicKey =
  'BJ5c6jLKBvvlCU1NgHtcxLSJ6w7K63vab172dWadKZg8y4ykooOiYDI4Zcm5ycZXQbIKqkUX6YhTUvX37TqvZzo';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
    .register('/serviceWorker.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);

      // Ask for Push Notification permission
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // Subscribe to push notifications
          subscribeToPushNotifications(registration);
        } else {
          console.error('Notification permission denied');
        }
      });
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
  });
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    console.log('Received message from service worker:', event);
    if (event.data && event.data.type === 'SW_UPDATED') {
      if (window.confirm('New version available. Reload?')) {
        window.location.reload();
      }
    }
  });
}

function subscribeToPushNotifications(registration) {
  // Get subscription token
  registration.pushManager
  .subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerPublicKey // Public key for VAPID
  })
  .then((subscription) => {
    console.log('Received Push Subscription:', subscription);
    // Send subscription object to AWS Lambda or backend to subscribe it to SNS
    sendSubscriptionToServer(subscription);
  })
  .catch((err) => console.error('Push subscription failed:', err));
}

async function sendSubscriptionToServer(subscription) {
  const subscription_payload = {
    type: 'subscribe_push',
    subscription_endpoint: subscription.endpoint,
    keys: {
      p256dh: subscription.getKey('p256dh'),
      auth: subscription.getKey('auth')
    },
    vapidPublicKey: applicationServerPublicKey,
    subscription: subscription
  };
  console.log(subscription);
  await fetch(notification_url, {
    method: 'POST',
    body: JSON.stringify(subscription_payload),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => {
    if (response.ok) {
      console.log('Subscribed to SNS successfully');
    } else {
      console.error('Failed to subscribe to SNS');
    }
  });
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <AuthProvider>
              <SidebarProvider>
                <Suspense fallback={<Spinner />}>
                  <App />
                </Suspense>
              </SidebarProvider>
            </AuthProvider>
          </DndProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
