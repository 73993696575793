import { getClassesActionTypes, getClassesAction } from '../actions/getClassesTypes';

interface getClassesState {
  data: string[];
  loading: boolean;
  load_error: string | null;
}

const initialState: getClassesState = {
  data: [],
  loading: false,
  load_error: null
};

const getClassesReducer = (state = initialState, action: getClassesAction): getClassesState => {
  switch (action.type) {
    case getClassesActionTypes.GET_CLASSES:
      return { ...state, loading: true };
    case getClassesActionTypes.GET_CLASSES_SUCCESS:
      return { ...state, data: action.payload, loading: false, load_error: null };
    case getClassesActionTypes.GET_CLASSES_ERROR:
      return { ...state, loading: false, load_error: action.payload };
    default:
      return state;
  }
};

export default getClassesReducer;
