// features/getJoinTeam/reducers/getJoinTeamReducer.ts
import { JoinTeamActionTypes, JoinTeamAction } from '../actions/getJoinTeamActionTypes';

interface JoinTeamState {
  data: string[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

const initialState: JoinTeamState = {
  data: [],
  loading: false,
  loaded: false,
  error: null
};

const joinTeamReducer = (state = initialState, action: JoinTeamAction): JoinTeamState => {
  switch (action.type) {
    case JoinTeamActionTypes.JOINTEAM_REQUEST:
      return { ...state, loading: true };
    case JoinTeamActionTypes.JOINTEAM_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case JoinTeamActionTypes.JOINTEAM_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default joinTeamReducer;
