import { GetLocationActionTypes, GetLocationAction } from './getLocationActionTypes';

export const getLocationRequest = (data: any): GetLocationAction => ({
  type: GetLocationActionTypes.GET_LOCATIONS,
  payload: data
});

export const getLocationRequestSuccess = (data: any): GetLocationAction => ({
  type: GetLocationActionTypes.GET_LOCATIONS_SUCCESS,
  payload: data
});

export const getLocationRequestError = (error: string): GetLocationAction => ({
  type: GetLocationActionTypes.GET_LOCATIONS_ERROR,
  payload: error
});
