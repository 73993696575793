import { takeLatest, put, call } from 'redux-saga/effects';
import { ClassNotesActionTypes } from '../actions/classNotesActionTypes';
import {
  classNotesRequestSuccess,
  classNotesRequestError,
  classNotesUpdateRequestSuccess,
  classNotesUpdateRequestError,
  classNotesDeleteRequestSuccess,
  classNotesDeleteRequestError
} from '../actions/classNotesActions';
import { apiService } from '../../../services/api';

function* classNotesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.classNotes, req.payload);

    // Dispatch the success action with the API response
    yield put(classNotesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(classNotesRequestError(error.message));
  }
}

function* classNotesUpdateRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.classNotes, req.payload);

    // Dispatch the success action with the API response
    yield put(classNotesUpdateRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(classNotesUpdateRequestError(error.message));
  }
}

function* classNotesDeleteRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.classNotes, req.payload);

    // Dispatch the success action with the API response
    yield put(classNotesDeleteRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(classNotesDeleteRequestError(error.message));
  }
}

export function* watchclassNotesRequest() {
  yield takeLatest(ClassNotesActionTypes.CLASS_NOTES_REQUEST, classNotesRequestSaga);
  yield takeLatest(ClassNotesActionTypes.CLASS_NOTES_UPDATE_REQUEST, classNotesUpdateRequestSaga);
  yield takeLatest(ClassNotesActionTypes.CLASS_NOTES_DELETE_REQUEST, classNotesDeleteRequestSaga);
}
