import { SendNotificationsActionTypes, SendNotificationsAction } from './SendNotificationsActionTypes';

export const sendNotificationsRequest = (data: any): SendNotificationsAction => ({
  type: SendNotificationsActionTypes.SEND_NOTIFICATIONS,
  payload: data
});

export const sendNotificationsRequestSuccess = (data: any): SendNotificationsAction => ({
  type: SendNotificationsActionTypes.SEND_NOTIFICATIONS_SUCCESS,
  payload: data
});

export const sendNotificationsRequestError = (error: string): SendNotificationsAction => ({
  type: SendNotificationsActionTypes.SEND_NOTIFICATIONS_ERROR,
  payload: error
});

export const sendNotificationsRequestReset = (): SendNotificationsAction => ({
  type: SendNotificationsActionTypes.RESET
});
