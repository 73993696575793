import { takeLatest, put, call } from 'redux-saga/effects';
import { HomeworkPagesActionTypes } from '../actions/HomeworkPagesActionTypes';
import {
  homeworkPagesRequestSuccess, homeworkPagesRequestError,
  updateHomeworkPagesRequestSuccess, updateHomeworkPagesRequestError,
  deleteHomeworkPagesRequestSuccess, deleteHomeworkPagesRequestError
} from '../actions/homeworkPagesActions';
import { apiService } from '../../../services/api'; 

function* homeworkPagesRequestSaga(req:any) {
  try {
    // Call the API using the call effect
    
    const response = yield call(apiService.getHomeworkPages, req.payload);

    // Dispatch the success action with the API response
    yield put(homeworkPagesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(homeworkPagesRequestError(error.message));
  }
}

function* updateHomeworkPagesRequestSaga(req:any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getHomeworkPages, req.payload);

    // Dispatch the success action with the API response
    yield put(updateHomeworkPagesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateHomeworkPagesRequestError(error.message));
  }
}

function* deleteHomeworkPagesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getHomeworkPages, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteHomeworkPagesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteHomeworkPagesRequestError(error.message));
  }
}

export function* watchHomeworkPagesRequest() {
  yield takeLatest(HomeworkPagesActionTypes.HOMEWORK_PAGES_REQUEST, homeworkPagesRequestSaga);
  yield takeLatest(HomeworkPagesActionTypes.UPDATE_HOMEWORK_PAGES_REQUEST, updateHomeworkPagesRequestSaga);
  yield takeLatest(HomeworkPagesActionTypes.DELETE_HOMEWORK_PAGES_REQUEST, deleteHomeworkPagesRequestSaga);
}
