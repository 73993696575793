import { takeLatest, put } from 'redux-saga/effects';
import { VerifyPasswordActionTypes } from '../actions/VerifyPasswordActionTypes';
import { verifyPasswordUserSuccess, verifyPasswordUserError } from '../actions/verifyPasswordActions';

const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-1' });
const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

function* verifyPasswordUserSaga(action) {
  try {
    const req = action.payload;

    const confirmForgotPasswordParams = {
      ClientId: localStorage.getItem('ClientId'),
      ConfirmationCode: req.verification_code,
      Password: req.password,
      Username: localStorage.getItem('user_name')
    };
    // Simulate an asynchronous API call
    const authPromise = new Promise(async (resolve, reject) => {
      await cognitoIdentityServiceProvider.confirmForgotPassword(confirmForgotPasswordParams, (err, result: any) => {
        if (err) {
          console.log('Password Request failed:', err);
          reject(err);
          localStorage.setItem('verify_password', 'false');
        } else {
          localStorage.setItem('verify_password', 'true');
          resolve({ result });
        }
      });
    });
    try {
      const authResult = yield authPromise;
      yield put(verifyPasswordUserSuccess(authResult));
    } catch (error: any) {
      yield put(verifyPasswordUserError(error));
    }
  } catch (error: any) {
    yield put(verifyPasswordUserError(error));
  }
}

export function* watchVerifyPasswordUser() {
  yield takeLatest(VerifyPasswordActionTypes.VERIFY_PASSWORD_USER, verifyPasswordUserSaga);
}
