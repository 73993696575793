export enum CourseNotificationActionTypes {
  COURSE_NOTIFICATION_REQUEST = 'courseNotification/COURSE_NOTIFICATION_REQUEST',
  COURSE_NOTIFICATION_REQUEST_SUCCESS = 'courseNotification/COURSE_NOTIFICATION_REQUEST_SUCCESS',
  COURSE_NOTIFICATION_REQUEST_ERROR = 'courseNotification/COURSE_NOTIFICATION_REQUEST_ERROR'
}

export interface CourseNotificationAction {
  type: CourseNotificationActionTypes;
  payload?: any;
}
