import { takeLatest, put, call } from 'redux-saga/effects';
import { getEventsActionTypes } from '../actions/getEventsActionTypes';
import { getEventsRequestSuccess, getEventsRequestError } from '../actions/getEventsActions';
import { apiService } from '../../../services/api';

function* getEventsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getEvents, req.payload);

    // Dispatch the success action with the API response
    yield put(getEventsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getEventsRequestError(error.message));
  }
}

export function* watchgetEventsRequest() {
  yield takeLatest(getEventsActionTypes.GET_EVENTS, getEventsRequestSaga);
}
