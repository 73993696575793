import { HomeworkActionTypes, HomeworkAction } from './HomeworkActionTypes';

export const homeworkRequest = (data: any): HomeworkAction => ({
  type: HomeworkActionTypes.HOMEWORK_REQUEST,
  payload: data
});

export const homeworkRequestSuccess = (data: any): HomeworkAction => ({
  type: HomeworkActionTypes.HOMEWORK_REQUEST_SUCCESS,
  payload: data
});

export const homeworkRequestError = (error: string): HomeworkAction => ({
  type: HomeworkActionTypes.HOMEWORK_REQUEST_ERROR,
  payload: error
});

export const updateHomeworkRequest = (data: any): HomeworkAction => ({
  type: HomeworkActionTypes.UPDATE_HOMEWORK_REQUEST,
  payload: data
});

export const updateHomeworkRequestSuccess = (data: any): HomeworkAction => ({
  type: HomeworkActionTypes.UPDATE_HOMEWORK_REQUEST_SUCCESS,
  payload: data
});

export const updateHomeworkRequestError = (error: string): HomeworkAction => ({
  type: HomeworkActionTypes.UPDATE_HOMEWORK_REQUEST_ERROR,
  payload: error
});

export const deleteHomeworkRequest = (data: any): HomeworkAction => ({
  type: HomeworkActionTypes.DELETE_HOMEWORK_REQUEST,
  payload: data
});

export const deleteHomeworkRequestSuccess = (data: any): HomeworkAction => ({
  type: HomeworkActionTypes.DELETE_HOMEWORK_REQUEST_SUCCESS,
  payload: data
});

export const deleteHomeworkRequestError = (error: string): HomeworkAction => ({
  type: HomeworkActionTypes.DELETE_HOMEWORK_REQUEST_ERROR,
  payload: error
});
