export enum RequestClassActionTypes {
  REQUEST_CLASS_REQUEST = 'requestClass/REQUEST_CLASS_REQUEST',
  REQUEST_CLASS_REQUEST_SUCCESS = 'requestClass/REQUEST_CLASS_REQUEST_SUCCESS',
  REQUEST_CLASS_REQUEST_ERROR = 'requestClass/REQUEST_CLASS_REQUEST_ERROR'
}

export interface RequestClassAction {
  type: RequestClassActionTypes;
  payload?: any;
}
