import { HomeworkActionTypes, HomeworkAction } from '../actions/HomeworkActionTypes';

interface HomeworkState {
  data: string[];
  loading: boolean;
  error: string | null;
}

const initialState: HomeworkState = {
  data: [],
  loading: false,
  error: null
};

const homeworkReducer = (state = initialState, action: HomeworkAction): HomeworkState => {
  switch (action.type) {
    case HomeworkActionTypes.HOMEWORK_REQUEST:
      return { ...state, loading: true };
    case HomeworkActionTypes.HOMEWORK_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HomeworkActionTypes.HOMEWORK_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case HomeworkActionTypes.UPDATE_HOMEWORK_REQUEST:
      return { ...state, loading: true };
    case HomeworkActionTypes.UPDATE_HOMEWORK_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HomeworkActionTypes.UPDATE_HOMEWORK_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case HomeworkActionTypes.DELETE_HOMEWORK_REQUEST:
      return { ...state, loading: true };
    case HomeworkActionTypes.DELETE_HOMEWORK_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HomeworkActionTypes.DELETE_HOMEWORK_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default homeworkReducer;
