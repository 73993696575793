import { AvailabilityActionTypes, AvailabilityAction } from './setAvailabilityActionTypes';

export const getAvailabilityRequest = (data: any): AvailabilityAction => ({
  type: AvailabilityActionTypes.GET_AVAILABILITY_REQUEST,
  payload: { data }
});

export const getAvailabilityRequestSuccess = (data: any): AvailabilityAction => ({
  type: AvailabilityActionTypes.GET_AVAILABILITY_REQUEST_SUCCESS,
  payload: data
});

export const getAvailabilityRequestError = (error: string): AvailabilityAction => ({
  type: AvailabilityActionTypes.GET_AVAILABILITY_REQUEST_ERROR,
  payload: error
});

export const setAvailabilityRequest = (
  availability_header_payload: any,
  scheduleData: any,
  selectedTimezoneData: any
): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_AVAILABILITY_REQUEST,
  payload: { availability_header_payload, scheduleData, selectedTimezoneData }
});

export const setAvailabilityRequestSuccess = (data: any): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_AVAILABILITY_REQUEST_SUCCESS,
  payload: data
});

export const setAvailabilityRequestError = (error: string): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_AVAILABILITY_REQUEST_ERROR,
  payload: error
});

export const updateAvailabilityRequest = (data: any): AvailabilityAction => ({
  type: AvailabilityActionTypes.UPDATE_AVAILABILITY_REQUEST,
  payload: data
});

export const updateAvailabilityRequestSuccess = (data: any): AvailabilityAction => ({
  type: AvailabilityActionTypes.UPDATE_AVAILABILITY_REQUEST_SUCCESS,
  payload: data
});

export const updateAvailabilityRequestError = (error: string): AvailabilityAction => ({
  type: AvailabilityActionTypes.UPDATE_AVAILABILITY_REQUEST_ERROR,
  payload: error
});

export const deleteAvailabilityRequest = (data: any): AvailabilityAction => ({
  type: AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST,
  payload: data
});

export const deleteAvailabilityRequestSuccess = (data: any): AvailabilityAction => ({
  type: AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST_SUCCESS,
  payload: data
});

export const deleteAvailabilityRequestError = (error: string): AvailabilityAction => ({
  type: AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST_ERROR,
  payload: error
});
