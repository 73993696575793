// features/login/reducers/loginReducer.ts
import { ResendCodeActionTypes, ResendCodeAction } from "../actions/ResendCodeActionTypes";

interface ResendCodeState {
  user: string | null;
  loading: boolean;
  error: string | null;
  loggedIn: boolean;
}

const initialState: ResendCodeState = {
  user: null,
  loading: false,
  error: null,
  loggedIn: false,
};

const resendCodeReducer = (state = initialState, action: ResendCodeAction): ResendCodeState => {
  switch (action.type) {
    case ResendCodeActionTypes.VERIFY_PASSWORD_USER:
      return { ...state, loading: true };
    case ResendCodeActionTypes.VERIFY_PASSWORD_USER_SUCCESS:
      return { ...state, loading: false, error: null };
    case ResendCodeActionTypes.VERIFY_PASSWORD_USER_ERROR:
      return { ...state, loading: false, error: action.payload };    
    default:
      return state;
  }
};

export default resendCodeReducer;
