export enum ManageCategoriesActionTypes {
  GET_CATEGORIES = 'manageCategories/GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS = 'manageCategories/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR = 'manageCategories/GET_CATEGORIES_ERROR',
  CREATE_CATEGORIES = 'manageCategories/CREATE_CATEGORIES',
  CREATE_CATEGORIES_SUCCESS = 'manageCategories/CREATE_CATEGORIES_SUCCESS',
  CREATE_CATEGORIES_ERROR = 'manageCategories/CREATE_CATEGORIES_ERROR',
  UPDATE_CATEGORIES = 'manageCategories/UPDATE_CATEGORIES',
  UPDATE_CATEGORIES_SUCCESS = 'manageCategories/UPDATE_CATEGORIES_SUCCESS',
  UPDATE_CATEGORIES_ERROR = 'manageCategories/UPDATE_CATEGORIES_ERROR'
}

export interface ManageCategoriesAction {
  type: ManageCategoriesActionTypes;
  payload?: any;
}
