// features/createClass/actions/createClassActions.ts
import { ClassNotesActionTypes, ClassNotesAction } from './classNotesActionTypes';

export const classNotesRequest = (data: any): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_REQUEST,
  payload: data
});

export const classNotesRequestSuccess = (data: any): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_REQUEST_SUCCESS,
  payload: data
});

export const classNotesRequestError = (error: string): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_REQUEST_ERROR,
  payload: error
});
export const classNotesUpdateRequest = (data: any): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_UPDATE_REQUEST,
  payload: data
});

export const classNotesUpdateRequestSuccess = (data: any): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_UPDATE_REQUEST_SUCCESS,
  payload: data
});

export const classNotesUpdateRequestError = (error: string): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_UPDATE_REQUEST_ERROR,
  payload: error
});
export const classNotesDeleteRequest = (data: any): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_DELETE_REQUEST,
  payload: data
});

export const classNotesDeleteRequestSuccess = (data: any): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_DELETE_REQUEST_SUCCESS,
  payload: data
});

export const classNotesDeleteRequestError = (error: string): ClassNotesAction => ({
  type: ClassNotesActionTypes.CLASS_NOTES_DELETE_REQUEST_ERROR,
  payload: error
});
