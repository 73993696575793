import { takeLatest, put, call } from 'redux-saga/effects';
import { StudentsActionTypes } from '../actions/GetStudentsActionTypes';
import {
  studentsRequestSuccess, studentsRequestError,
  updateStudentsRequestSuccess, updateStudentsRequestError,
  deleteStudentsRequestSuccess, deleteStudentsRequestError
} from '../actions/getStudentsActions';
import { apiService } from '../../../services/api'; 

function* studentsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getStudents, req.payload);

    // Dispatch the success action with the API response
    yield put(studentsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(studentsRequestError(error.message));
  }
}

function* updateStudentsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getStudents, req.payload);

    // Dispatch the success action with the API response
    yield put(updateStudentsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateStudentsRequestError(error.message));
  }
}

function* deleteStudentsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getStudents, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteStudentsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteStudentsRequestError(error.message));
  }
}

export function* watchStudentsRequest() {
  yield takeLatest(StudentsActionTypes.STUDENTS_REQUEST, studentsRequestSaga);
  yield takeLatest(StudentsActionTypes.UPDATE_STUDENTS_REQUEST, updateStudentsRequestSaga);
  yield takeLatest(StudentsActionTypes.DELETE_STUDENTS_REQUEST, deleteStudentsRequestSaga);
}
