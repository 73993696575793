export enum HeaderActionTypes {
    HEADER_TITLE = 'header/HEADER_TITLE',
    HEADER_TITLE_REQUEST_SUCCESS = 'header/HEADER_TITLE_REQUEST_SUCCESS',
    HEADER_TITLE_REQUEST_ERROR = 'header/HEADER_TITLE_REQUEST_ERROR',
  }
  
  export interface HeaderAction {
    type: HeaderActionTypes;
    payload?: any;
  }
  