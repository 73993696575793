// store/configureStore.js
import rootReducer, { RootState } from './rootReducer';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootSaga from './rootSaga';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import type { Action } from '@reduxjs/toolkit';

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage
  // Optionally, add more configuration here
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {} // Pass any additional arguments here if needed
      },
      serializableCheck: false, // Set to false if you have non-serializable data
      immutableCheck: false // Set to false if you want to skip immutable state checks
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production' // Enable Redux DevTools in development
});

export const persistor: Persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
// export type AppState = ReturnType<typeof rootReducer>;
export type AppState = RootState;
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;
export default store;
