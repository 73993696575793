export enum AvailabilityActionTypes {
  GET_AVAILABILITY_REQUEST = 'setAvailability/GET_AVAILABILITY_REQUEST',
  GET_AVAILABILITY_REQUEST_SUCCESS = 'setAvailability/GET_AVAILABILITY_REQUEST_SUCCESS',
  GET_AVAILABILITY_REQUEST_ERROR = 'setAvailability/GET_AVAILABILITY_REQUEST_ERROR',
  SET_AVAILABILITY_REQUEST = 'setAvailability/SET_AVAILABILITY_REQUEST',
  SET_AVAILABILITY_REQUEST_SUCCESS = 'setAvailability/SET_AVAILABILITY_REQUEST_SUCCESS',
  SET_AVAILABILITY_REQUEST_ERROR = 'setAvailability/SET_AVAILABILITY_REQUEST_ERROR',
  UPDATE_AVAILABILITY_REQUEST = 'setAvailability/UPDATE_AVAILABILITY_REQUEST',
  UPDATE_AVAILABILITY_REQUEST_SUCCESS = 'setAvailability/UPDATE_AVAILABILITY_REQUEST_SUCCESS',
  UPDATE_AVAILABILITY_REQUEST_ERROR = 'setAvailability/UPDATE_AVAILABILITY_REQUEST_ERROR',
  DELETE_AVAILABILITY_REQUEST = 'setAvailability/DELETE_AVAILABILITY_REQUEST',
  DELETE_AVAILABILITY_REQUEST_SUCCESS = 'setAvailability/DELETE_AVAILABILITY_REQUEST_SUCCESS',
  DELETE_AVAILABILITY_REQUEST_ERROR = 'setAvailability/DELETE_AVAILABILITY_REQUEST_ERROR'
}

export interface AvailabilityAction {
  type: AvailabilityActionTypes;
  payload?: any;
}
