import { takeLatest, put, call } from 'redux-saga/effects';
import { GetLocationActionTypes } from '../actions/getLocationActionTypes';
import { getLocationRequestSuccess, getLocationRequestError } from '../actions/getLocationActions';
import { apiService } from '../../../services/api';

function* getLocationRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getLocations, req.payload);

    // Dispatch the success action with the API response
    yield put(getLocationRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getLocationRequestError(error.message));
  }
}

export function* watchgetLocationRequest() {
  yield takeLatest(GetLocationActionTypes.GET_LOCATIONS, getLocationRequestSaga);
}
