import { TutorsActionTypes, TutorsAction } from './getTutorActionTypes';

export const tutorsRequest = (data: any): TutorsAction => ({
  type: TutorsActionTypes.TUTORS_REQUEST,
  payload: data
});

export const tutorsRequestSuccess = (data: any): TutorsAction => ({
  type: TutorsActionTypes.TUTORS_REQUEST_SUCCESS,
  payload: data
});

export const tutorsRequestError = (error: string): TutorsAction => ({
  type: TutorsActionTypes.TUTORS_REQUEST_ERROR,
  payload: error
});

export const submitTutorsRequest = (data: any): TutorsAction => ({
  type: TutorsActionTypes.SUBMIT_TUTORS_REQUEST,
  payload: data
});

export const submitTutorsRequestSuccess = (data: any): TutorsAction => ({
  type: TutorsActionTypes.SUBMIT_TUTORS_REQUEST_SUCCESS,
  payload: data
});

export const submitTutorsRequestError = (error: string): TutorsAction => ({
  type: TutorsActionTypes.SUBMIT_TUTORS_REQUEST_ERROR,
  payload: error
});
