export enum JoinTeamActionTypes {
    JOINTEAM_REQUEST = 'getJoinTeamRequest/JOINTEAM_REQUEST',
    JOINTEAM_REQUEST_SUCCESS = 'getJoinTeamRequest/JOINTEAM_REQUEST_SUCCESS',
    JOINTEAM_REQUEST_ERROR = 'getJoinTeamRequest/JOINTEAM_REQUEST_ERROR'
  }
  
  export interface JoinTeamAction {
    type: JoinTeamActionTypes;
    payload?: any;
  }
  