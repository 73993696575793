//features/courseListing/reducers/listingReducer.ts
import { CourseListingActionTypes, CourseListingAction } from '../actions/CourseListingActionTypes';

interface CourseListingState {
  data: string[];
  teacher_data: string[];
  loading: boolean;
  teacher_loading: boolean;
  error: string | null;
  teacher_error: string | null;
}

const initialState: CourseListingState = {
  data: [],
  loading: false,
  error: null,
  teacher_data: [],
  teacher_loading: false,
  teacher_error: null
};

const courseListingReducer = (state = initialState, action: CourseListingAction): CourseListingState => {
  switch (action.type) {
    case CourseListingActionTypes.COURSE_LISTING_REQUEST:
      return { ...state, loading: true };
    case CourseListingActionTypes.COURSE_LISTING_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case CourseListingActionTypes.COURSE_LISTING_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case CourseListingActionTypes.COURSE_LISTING_REQUEST_TEACHERS:
      return { ...state, teacher_loading: true };
    case CourseListingActionTypes.COURSE_LISTING_REQUEST_TEACHERS_SUCCESS:
      return { ...state, teacher_data: action.payload, teacher_loading: false, teacher_error: null };
    case CourseListingActionTypes.COURSE_LISTING_REQUEST_TEACHERS_ERROR:
      return { ...state, teacher_loading: false, teacher_error: action.payload };
    default:
      return state;
  }
};

export default courseListingReducer;
