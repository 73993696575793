export enum ManageCourseActionTypes {
  GET_COURSE = 'manageCourse/GET_COURSE',
  GET_COURSE_SUCCESS = 'manageCourse/GET_COURSE_SUCCESS',
  GET_COURSE_ERROR = 'manageCourse/GET_COURSE_ERROR',
  CREATE_COURSE = 'manageCourse/CREATE_COURSE',
  CREATE_COURSE_SUCCESS = 'manageCourse/CREATE_COURSE_SUCCESS',
  CREATE_COURSE_ERROR = 'manageCourse/CREATE_COURSE_ERROR',
  UPDATE_COURSE = 'manageCourse/UPDATE_COURSE',
  UPDATE_COURSE_SUCCESS = 'manageCourse/UPDATE_COURSE_SUCCESS',
  UPDATE_COURSE_ERROR = 'manageCourse/UPDATE_COURSE_ERROR'
}

export interface ManageCourseAction {
  type: ManageCourseActionTypes;
  payload?: any;
}
