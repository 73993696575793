export enum ManageLocationActionTypes {
    MANAGE_LOCATION = 'manageLocations/GET_LOCATION',
    MANAGE_LOCATION_SUCCESS = 'manageLocations/GET_LOCATION_SUCCESS',
    MANAGE_LOCATION_ERROR = 'manageLocations/GET_LOCATION_ERROR',
    CREATE_LOCATION= 'manageLocations/GET_LOCATION',
    CREATE_LOCATION_SUCCESS='manageLocations/GET_LOCATION',
    CREATE_LOCATION_ERROR='manageLocations/GET_LOCATION',
    UPDATE_LOCATION='manageLocations/GET_LOCATION',
    UPDATE_LOCATION_SUCCESS='manageLocations/GET_LOCATION',
    UPDATE_LOCATION_ERROR='manageLocations/GET_LOCATION',
    DELETE_LOCATION='manageLocations/GET_LOCATION',
    DELETE_LOCATION_SUCCESS='manageLocations/GET_LOCATION',
    DELETE_LOCATION_ERROR='manageLocations/GET_LOCATION',
} 
export interface ManageLocationAction {
    type: ManageLocationActionTypes;
    payload?: any;
}