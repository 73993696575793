// features/verifyPassword/actions/verifyPasswordActions.ts
import { ChangePasswordActionTypes, ChangePasswordAction } from "./ChangePasswordActionTypes";

export const changePasswordUser = (req: any): ChangePasswordAction => ({
  type: ChangePasswordActionTypes.CHANGE_PASSWORD_USER,
  payload: req,
});

export const changePasswordUserSuccess = (data: any): ChangePasswordAction => ({
  type: ChangePasswordActionTypes.CHANGE_PASSWORD_USER_SUCCESS,
  payload: data,
});

export const changePasswordUserError = (error: string): ChangePasswordAction => ({
  type: ChangePasswordActionTypes.CHANGE_PASSWORD_USER_ERROR,
});