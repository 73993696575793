import { takeLatest, put } from 'redux-saga/effects';
import { ChangePasswordActionTypes } from '../actions/ChangePasswordActionTypes';
import { changePasswordUserSuccess, changePasswordUserError } from '../actions/changePasswordActions';

const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-1' });
const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

function* changePasswordUserSaga(action) {
  try {
    const req = action.payload;

    const changePasswordParams = {
      AccessToken: localStorage.getItem('accessToken'),
      PreviousPassword: req.currentPassword,
      ProposedPassword: req.newPassword
    };
    // Simulate an asynchronous API call
    const authPromise = new Promise(async (resolve, reject) => {
      await cognitoIdentityServiceProvider.changePassword(changePasswordParams, (err, result: any) => {
        if (err) {
          console.log('Change Password failed:', err);
          reject(err);
          localStorage.setItem('password_changed', 'false');
        } else {
          localStorage.setItem('password_changed', 'true');
          resolve({ result });
        }
      });
    });
    try {
      const authResult = yield authPromise;
      yield put(changePasswordUserSuccess(authResult));
    } catch (error: any) {
      yield put(changePasswordUserError(error));
    }
  } catch (error: any) {
    yield put(changePasswordUserError(error));
  }
}

export function* watchChangePasswordUser() {
  yield takeLatest(ChangePasswordActionTypes.CHANGE_PASSWORD_USER, changePasswordUserSaga);
}
