import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageCategoriesActionTypes } from '../actions/manageCategoriesActionTypes';
import {
  getCategoriesRequestSuccess,
  getCategoriesRequestError,
  createCategoriesRequestSuccess,
  createCategoriesRequestError,
  updateCategoriesRequestSuccess,
  updateCategoriesRequestError
} from '../actions/manageCategoriesActions';
import { apiService } from '../../../services/api';

function* getCategoriesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageCategories, req.payload);

    // Dispatch the success action with the API response
    yield put(getCategoriesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getCategoriesRequestError(error.message));
  }
}

function* createCategoriesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageCategories, req.payload);

    // Dispatch the success action with the API response
    yield put(createCategoriesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(createCategoriesRequestError(error.message));
  }
}

function* updateCategoriesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageCategories, req.payload);

    // Dispatch the success action with the API response
    yield put(updateCategoriesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateCategoriesRequestError(error.message));
  }
}

export function* watchmanageCategoriesRequest() {
  yield takeLatest(ManageCategoriesActionTypes.GET_CATEGORIES, getCategoriesRequestSaga);
  yield takeLatest(ManageCategoriesActionTypes.CREATE_CATEGORIES, createCategoriesRequestSaga);
  yield takeLatest(ManageCategoriesActionTypes.UPDATE_CATEGORIES, updateCategoriesRequestSaga);
}
