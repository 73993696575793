import { takeLatest, put, call } from 'redux-saga/effects';
import { ClassModelsActionTypes } from '../actions/ClassModelsActionTypes';
import { classModelsRequestSuccess, classModelsRequestError } from '../actions/classModelsActions';
import { apiService } from '../../../services/api';

function* classModelsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.classModels, req.payload);

    // Dispatch the success action with the API response
    yield put(classModelsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(classModelsRequestError(error.message));
  }
}

export function* watchClassModelsRequest() {
  yield takeLatest(ClassModelsActionTypes.CLASS_MODELS_REQUEST, classModelsRequestSaga);
}
