// features/eventRegistration/reducers/eventRegistrationReducer.tsx
import { eventRegistrationActionTypes, eventRegistrationAction } from "../actions/eventRegistrationActionTypes";

interface RegisterUserState {
  data: string | null;
  account: string | null;
  loading: boolean;
   error: string | null;
}

const initialState: RegisterUserState = {
  data: null,
  account: null,
  loading: false,
  error: null,
};

const eventRegistrationReducer = (state = initialState, action: eventRegistrationAction): RegisterUserState => {
  switch (action.type) {
    case eventRegistrationActionTypes.EVENT_REGISTRATION:
      return { ...state, loading: true };
    case eventRegistrationActionTypes.EVENT_REGISTRATION_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case eventRegistrationActionTypes.EVENT_REGISTRATION_ERROR:
      return { ...state, loading: false, error: action.payload };    
    default:
      return state;
  }
};

export default eventRegistrationReducer;
