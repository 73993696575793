import { GetResourcesActionTypes, GetResourcesAction } from '../actions/getResourcesActionTypes';

interface GetResourcesState {
  data: string[];
  managing: boolean;
  manage_error: string | null;
}

const initialState: GetResourcesState = {
  data: [],
  managing: false,
  manage_error: null
};

const getResourcesReducer = (state = initialState, action: GetResourcesAction): GetResourcesState => {
  switch (action.type) {
    case GetResourcesActionTypes.GET_RESOURCES:
      return { ...state, managing: true };
    case GetResourcesActionTypes.GET_RESOURCES_SUCCESS:
      return { ...state, data: action.payload, managing: false, manage_error: null };
    case GetResourcesActionTypes.GET_RESOURCES_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
    default:
      return state;
  }
};

export default getResourcesReducer;
