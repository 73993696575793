export enum HomeworkQuestionsActionTypes {
  HOMEWORK_QUESTIONS_REQUEST = 'checkHomeworkQuestions/HOMEWORK_QUESTIONS_REQUEST',
  HOMEWORK_QUESTIONS_REQUEST_SUCCESS = 'checkHomeworkQuestions/HOMEWORK_QUESTIONS_REQUEST_SUCCESS',
  HOMEWORK_QUESTIONS_REQUEST_ERROR = 'checkHomeworkQuestions/HOMEWORK_QUESTIONS_REQUEST_ERROR',
  UPDATE_HOMEWORK_QUESTIONS_REQUEST = 'checkHomeworkQuestions/UPDATE_HOMEWORK_QUESTIONS_REQUEST',
  UPDATE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS = 'checkHomeworkQuestions/UPDATE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS',
  UPDATE_HOMEWORK_QUESTIONS_REQUEST_ERROR = 'checkHomeworkQuestions/UPDATE_HOMEWORK_QUESTIONS_REQUEST_ERROR',
  DELETE_HOMEWORK_QUESTIONS_REQUEST = 'checkHomeworkQuestions/DELETE_HOMEWORK_QUESTIONS_REQUEST',
  DELETE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS = 'checkHomeworkQuestions/DELETE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS',
  DELETE_HOMEWORK_QUESTIONS_REQUEST_ERROR = 'checkHomeworkQuestions/DELETE_HOMEWORK_QUESTIONS_REQUEST_ERROR',
  RESET_QUESTION_RESPONSE_STATE = 'checkHomeworkQuestions/RESET_QUESTION_RESPONSE_STATE'
}

export interface HomeworkQuestionsAction {
  type: HomeworkQuestionsActionTypes;
  payload?: any;
}
