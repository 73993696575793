// features/forgotPassword/actions/forgotPasswordActions.ts
import { ForgotPasswordActionTypes, ForgotPasswordAction } from "./ForgotPasswordActionTypes";

export const forgotPasswordAccount = (): ForgotPasswordAction => ({
  type: ForgotPasswordActionTypes.FORGOT_PASSWORD_ACCOUNT,
});

export const forgotPasswordAccountSuccess = (data: any): ForgotPasswordAction => ({
  type: ForgotPasswordActionTypes.FORGOT_PASSWORD_ACCOUNT_SUCCESS,
  payload: data,
});

export const forgotPasswordAccountError = (error: string): ForgotPasswordAction => ({
  type: ForgotPasswordActionTypes.FORGOT_PASSWORD_ACCOUNT_ERROR,
});

export const forgotPasswordUser = (req: any): ForgotPasswordAction => ({
  type: ForgotPasswordActionTypes.FORGOT_PASSWORD_USER,
  payload: req,
});

export const forgotPasswordUserSuccess = (data: any): ForgotPasswordAction => ({
  type: ForgotPasswordActionTypes.FORGOT_PASSWORD_USER_SUCCESS,
  payload: data,
});

export const forgotPasswordUserError = (error: string): ForgotPasswordAction => ({
  type: ForgotPasswordActionTypes.FORGOT_PASSWORD_USER_ERROR,
});