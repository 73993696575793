import { GetLocationActionTypes, GetLocationAction } from '../actions/getLocationActionTypes';

interface GetLocationState {
  data: string[];
  managing: boolean;
  manage_error: string | null;
}

const initialState: GetLocationState = {
  data: [],
  managing: false,
  manage_error: null
};

const getLocationReducer = (state = initialState, action: GetLocationAction): GetLocationState => {
  switch (action.type) {
    case GetLocationActionTypes.GET_LOCATIONS:
      return { ...state, managing: true };
    case GetLocationActionTypes.GET_LOCATIONS_SUCCESS:
      return { ...state, data: action.payload, managing: false, manage_error: null };
    case GetLocationActionTypes.GET_LOCATIONS_ERROR:
      return { ...state, managing: false, manage_error: action.payload };
    default:
      return state;
  }
};

export default getLocationReducer;
