import { takeLatest, put, call } from 'redux-saga/effects';
import { CourseDetailActionTypes } from '../actions/CourseDetailActionTypes';
import { courseDetailRequestSuccess, courseDetailRequestError } from '../actions/courseDetailActions';
import { apiService } from '../../../services/api';

function* courseDetailRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.courseDetail, req.payload);

    // Dispatch the success action with the API response
    yield put(courseDetailRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(courseDetailRequestError(error.message));
  }
}

export function* watchCourseDetailRequest() {
  yield takeLatest(CourseDetailActionTypes.COURSE_DETAIL_REQUEST, courseDetailRequestSaga);
}
