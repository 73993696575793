import axios from '../../Utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppState } from '../configureStore';
import type { PayloadAction } from '@reduxjs/toolkit';

const API_URL = '/api/data/Gen3classes/Gen3ClassesData';

interface StateType {
  Gen3classes: any[];
  Gen3classesContent: number;
  gen3classSearch: string;
}

const initialState = {
  Gen3classes: [],
  Gen3classesContent: 1,
  gen3classSearch: ''
};

export const Gen3ClassesSlice = createSlice({
  name: 'Gen3classes',
  initialState,
  reducers: {
    getGen3Classes: (state, action) => {
      state.Gen3classes = action.payload;
    },
    SearchGen3Classes: (state, action) => {
      state.gen3classSearch = action.payload;
    },
    SelectGen3Class: (state, action) => {
      state.Gen3classesContent = action.payload;
    },

    DeleteGen3Class(state: StateType, action) {
      const index = state.Gen3classes.findIndex((gen3class) => gen3class.id === action.payload);
      state.Gen3classes.splice(index, 1);
    },

    UpdateGen3Class: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        state.Gen3classes = state.Gen3classes.map((gen3class) =>
          gen3class.id === action.payload.id
            ? { ...gen3class, [action.payload.field]: action.payload.value }
            : gen3class
        );
      },
      prepare: (id, field, value) => {
        return {
          payload: { id, field, value }
        };
      }
    },

    addGen3Class: {
      reducer: (state: StateType, action: PayloadAction<any>) => {
        state.Gen3classes.push(action.payload);
      },
      prepare: (id, title, color) => {
        return { payload: { id, title, color, datef: new Date().toDateString(), deleted: false } };
      }
    }
  }
});

export const { SearchGen3Classes, getGen3Classes, SelectGen3Class, DeleteGen3Class, UpdateGen3Class, addGen3Class } =
  Gen3ClassesSlice.actions;

export const fetchGen3Classes = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${API_URL}`);
    dispatch(getGen3Classes(response.data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export default Gen3ClassesSlice.reducer;
