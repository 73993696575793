// features/login/actions/LoginActionTypes.ts
export enum ForgotPasswordActionTypes {
  FORGOT_PASSWORD_ACCOUNT = 'forgotPassword/FORGOT_PASSWORD_ACCOUNT',
  FORGOT_PASSWORD_ACCOUNT_SUCCESS = 'forgotPassword/FORGOT_PASSWORD_ACCOUNT_SUCCESS',
  FORGOT_PASSWORD_ACCOUNT_ERROR = 'forgotPassword/FORGOT_PASSWORD_ACCOUNT_ERROR',
  FORGOT_PASSWORD_USER = 'forgotPassword/FORGOT_PASSWORD_USER',
  FORGOT_PASSWORD_USER_SUCCESS = 'forgotPassword/FORGOT_PASSWORD_USER_SUCCESS',
  FORGOT_PASSWORD_USER_ERROR = 'forgotPassword/FORGOT_PASSWORD_USER_ERROR',
}

export interface ForgotPasswordAction {
  type: ForgotPasswordActionTypes;
  payload?: any;
}
