import { CourseDetailActionTypes, CourseDetailAction } from '../actions/CourseDetailActionTypes';

interface CourseDetailState {
  data: string[];
  loading: boolean;
  error: string | null;
}

const initialState: CourseDetailState = {
  data: [],
  loading: false,
  error: null
};

const courseDetailReducer = (state = initialState, action: CourseDetailAction): CourseDetailState => {
  switch (action.type) {
    case CourseDetailActionTypes.COURSE_DETAIL_REQUEST:
      return { ...state, loading: true };
    case CourseDetailActionTypes.COURSE_DETAIL_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case CourseDetailActionTypes.COURSE_DETAIL_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default courseDetailReducer;
