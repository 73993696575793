// features/login/reducers/loginReducer.ts
import { ForgotPasswordActionTypes, ForgotPasswordAction } from "../actions/ForgotPasswordActionTypes";

interface LoginState {
  user: string | null;
  account: string | null;
  loading: boolean;
  error: string | null;
  loggedIn: boolean;
}

const initialState: LoginState = {
  user: null,
  account: null,
  loading: false,
  error: null,
  loggedIn: false,
};

const forgotPasswordReducer = (state = initialState, action: ForgotPasswordAction): LoginState => {
  switch (action.type) {
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_ACCOUNT:
      return { ...state, loading: true };
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_ACCOUNT_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null, loggedIn: true };
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_ACCOUNT_ERROR:
      return { ...state, loading: false, error: action.payload, loggedIn: false };
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_USER:
      return { ...state, loading: true };
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_USER_SUCCESS:
      return { ...state, account: action.payload, loading: false, error: null };
    case ForgotPasswordActionTypes.FORGOT_PASSWORD_USER_ERROR:
      return { ...state, loading: false, error: action.payload };    
    default:
      return state;
  }
};

export default forgotPasswordReducer;
