export enum StudentsActionTypes {
    STUDENTS_REQUEST = 'getStudents/STUDENTS_REQUEST',
    STUDENTS_REQUEST_SUCCESS = 'getStudents/STUDENTS_REQUEST_SUCCESS',
  STUDENTS_REQUEST_ERROR = 'getStudents/STUDENTS_REQUEST_ERROR',
  UPDATE_STUDENTS_REQUEST = 'getStudents/UPDATE_STUDENTS_REQUEST',
  UPDATE_STUDENTS_REQUEST_SUCCESS = 'getStudents/UPDATE_STUDENTS_REQUEST_SUCCESS',
  UPDATE_STUDENTS_REQUEST_ERROR = 'getStudents/UPDATE_STUDENTS_REQUEST_ERROR',    
  DELETE_STUDENTS_REQUEST = 'getStudents/DELETE_STUDENTS_REQUEST',
  DELETE_STUDENTS_REQUEST_SUCCESS = 'getStudents/DELETE_STUDENTS_REQUEST_SUCCESS',
  DELETE_STUDENTS_REQUEST_ERROR = 'getStudents/DELETE_STUDENTS_REQUEST_ERROR',      
  }
  
  export interface StudentsAction {
    type: StudentsActionTypes;
    payload?: any;
  }
  