import { takeLatest, put, call } from 'redux-saga/effects';
import { MessagesActionTypes } from '../actions/GetMessagesActionTypes';
import {
  messagesRequestSuccess,
  messagesRequestError,
  updateMessagesRequestSuccess,
  updateMessagesRequestError,
  deleteMessagesRequestSuccess,
  deleteMessagesRequestError
} from '../actions/getMessagesActions';
import { apiService } from '../../../services/api';

function* messagesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getMessages, req.payload);

    // Dispatch the success action with the API response
    yield put(messagesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(messagesRequestError(error.message));
  }
}

function* updateMessagesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.updateMessages, req.payload);

    // Dispatch the success action with the API response
    yield put(updateMessagesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateMessagesRequestError(error.message));
  }
}

function* deleteMessagesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getMessages, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteMessagesRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteMessagesRequestError(error.message));
  }
}

export function* watchMessagesRequest() {
  yield takeLatest(MessagesActionTypes.MESSAGES_REQUEST, messagesRequestSaga);
  yield takeLatest(MessagesActionTypes.UPDATE_MESSAGES_REQUEST, updateMessagesRequestSaga);
  yield takeLatest(MessagesActionTypes.DELETE_MESSAGES_REQUEST, deleteMessagesRequestSaga);
}
