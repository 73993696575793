import {
  BringCourseToYourLocationAction,
  BringCourseToYourLocationActionTypes
} from './bringCourseToYourLocationActionTypes';

export const bringCourseToYourLocation = (data: any): BringCourseToYourLocationAction => ({
  type: BringCourseToYourLocationActionTypes.BRING_COURSE_REQUEST,
  payload: data
});

export const bringCourseToYourLocationSuccess = (data: any): BringCourseToYourLocationAction => ({
  type: BringCourseToYourLocationActionTypes.BRING_COURSE_REQUEST_SUCCESS,
  payload: data
});

export const bringCourseToYourLocationError = (error: string): BringCourseToYourLocationAction => ({
  type: BringCourseToYourLocationActionTypes.BRING_COURSE_REQUEST_ERROR,
  payload: error
});
