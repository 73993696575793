import { createReducer } from '@reduxjs/toolkit';
import { manageResourcesRequest } from '../actions/manageResourcesActions';

interface ManageResourcesState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: ManageResourcesState = {
  data: null,
  loading: false,
  error: null
};

export const manageResourcesReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(manageResourcesRequest.pending, (state) => {
    state.loading = true;
    state.error = null;
  })
  .addCase(manageResourcesRequest.fulfilled, (state, action) => {
    state.loading = false;

    if (action.payload) {
      if (action.payload.resource_id) {
        // This is likely the response from 'add' action
        state.data = action.payload;
      } else if (action.payload.data && action.payload.data.resource_id && action.payload.data.image) {
        // This is likely the response from 'updateImage' action
        if (state.data && Array.isArray(state.data.message)) {
          state.data.message = state.data.message.map((resource) =>
            resource.resource_id === action.payload.data.resource_id
              ? { ...resource, image: action.payload.data.image }
              : resource
          );
        }
      } else {
        // For other types of responses, just update the state
        state.data = action.payload;
      }
    } else {
      console.error('Unexpected payload structure:', action.payload);
      state.error = 'Received invalid data from the server';
    }
  })
  .addCase(manageResourcesRequest.rejected, (state, action) => {
    state.loading = false;
    state.error = (action.payload as string) || 'An error occurred';
  });
});

export default manageResourcesReducer;
