export enum Gen3RewardsActionTypes {
  GEN3_REWARDS_REQUEST = 'gen3Rewards/GEN3_REWARDS_REQUEST',
  GEN3_REWARDS_REQUEST_SUCCESS = 'gen3Rewards/GEN3_REWARDS_REQUEST_SUCCESS',
  GEN3_REWARDS_REQUEST_ERROR = 'gen3Rewards/GEN3_REWARDS_REQUEST_ERROR'
}

export interface Gen3RewardsAction {
  type: Gen3RewardsActionTypes;
  payload?: any;
}
