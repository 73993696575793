// features/createClass/actions/createClassActions.ts
import { ManageCourseActionTypes, ManageCourseAction } from './manageCourseActionTypes';

export const getCourseRequest = (data: any): ManageCourseAction => ({
  type: ManageCourseActionTypes.GET_COURSE,
  payload: data
});

export const getCourseRequestSuccess = (data: any): ManageCourseAction => ({
  type: ManageCourseActionTypes.GET_COURSE_SUCCESS,
  payload: data
});

export const getCourseRequestError = (error: string): ManageCourseAction => ({
  type: ManageCourseActionTypes.GET_COURSE_ERROR,
  payload: error
});

export const createCourseRequest = (data: any): ManageCourseAction => ({
  type: ManageCourseActionTypes.CREATE_COURSE,
  payload: data
});

export const createCourseRequestSuccess = (data: any): ManageCourseAction => ({
  type: ManageCourseActionTypes.CREATE_COURSE_SUCCESS,
  payload: data
});

export const createCourseRequestError = (error: string): ManageCourseAction => ({
  type: ManageCourseActionTypes.CREATE_COURSE_ERROR,
  payload: error
});

export const updateCourseRequest = (data: any): ManageCourseAction => ({
  type: ManageCourseActionTypes.UPDATE_COURSE,
  payload: data
});

export const updateCourseRequestSuccess = (data: any): ManageCourseAction => ({
  type: ManageCourseActionTypes.UPDATE_COURSE_SUCCESS,
  payload: data
});

export const updateCourseRequestError = (error: string): ManageCourseAction => ({
  type: ManageCourseActionTypes.UPDATE_COURSE_ERROR,
  payload: error
});
