import { CourseNotificationAction, CourseNotificationActionTypes } from './courseNotificationActionTypes';

export const courseNotification = (data: any): CourseNotificationAction => ({
  type: CourseNotificationActionTypes.COURSE_NOTIFICATION_REQUEST,
  payload: data
});

export const courseNotificationSuccess = (data: any): CourseNotificationAction => ({
  type: CourseNotificationActionTypes.COURSE_NOTIFICATION_REQUEST_SUCCESS,
  payload: data
});

export const courseNotificationError = (error: string): CourseNotificationAction => ({
  type: CourseNotificationActionTypes.COURSE_NOTIFICATION_REQUEST_ERROR,
  payload: error
});
