import { HeaderActionTypes, HeaderAction } from './constants';

interface HeaderTitleState {
  data: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: HeaderTitleState = {
  data: '',
  loading: false,
  error: null
};

const headerTitleReducer = (state = initialState, action: HeaderAction): HeaderTitleState => {
  switch (action.type) {
    case HeaderActionTypes.HEADER_TITLE:
      return { ...state, loading: true };
    case HeaderActionTypes.HEADER_TITLE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HeaderActionTypes.HEADER_TITLE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default headerTitleReducer;
