import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageEventsActionTypes } from '../actions/manageEventsActionTypes';
import { manageEventsRequestSuccess, manageEventsRequestError } from '../actions/manageEventsActions';
import { apiService } from '../../../services/api';

function* manageEventsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageEvents, req.payload);

    // Dispatch the success action with the API response
    yield put(manageEventsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(manageEventsRequestError(error.message));
  }
}

export function* watchmanageEventsRequest() {
  yield takeLatest(ManageEventsActionTypes.MANAGE_EVENTS, manageEventsRequestSaga);
}
