// features/login/reducers/loginReducer.ts
import { NewPasswordActionTypes, NewPasswordAction } from "../actions/NewPasswordActionTypes";

interface NewPasswordState {
  user: string | null;
  loading: boolean;
  error: string | null;
  newPassword: boolean;
  loggedIn: boolean;
}

const initialState: NewPasswordState = {
  user: null,
  loading: false,
  error: null,
  newPassword: false,
  loggedIn: false,
};

const newPasswordReducer = (state = initialState, action: NewPasswordAction): NewPasswordState => {
  switch (action.type) {
    case NewPasswordActionTypes.NEW_PASSWORD_USER:
      return { ...state, loading: true, newPassword: action.payload.newPasswordRequired, loggedIn: action.payload.loggedIn };
    case NewPasswordActionTypes.NEW_PASSWORD_USER_SUCCESS:
      return {
        ...state, loading: false, error: null,
        newPassword: action.payload.newPasswordRequired,
        loggedIn: action.payload.loggedIn
      };
    case NewPasswordActionTypes.NEW_PASSWORD_USER_ERROR:
      return { ...state, loading: false, error: action.payload };    
    default:
      return state;
  }
};

export default newPasswordReducer;
