
export enum VerifyPasswordActionTypes {
  VERIFY_PASSWORD_USER = 'verifyPassword/VERIFY_PASSWORD_USER',
  VERIFY_PASSWORD_USER_SUCCESS = 'verifyPassword/VERIFY_PASSWORD_USER_SUCCESS',
  VERIFY_PASSWORD_USER_ERROR = 'verifyPassword/VERIFY_PASSWORD_USER_ERROR',
}

export interface VerifyPasswordAction {
  type: VerifyPasswordActionTypes;
  payload?: any;
}
