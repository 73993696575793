import { HomeworkQuestionsActionTypes, HomeworkQuestionsAction } from '../actions/HomeworkQuestionsActionTypes';

interface HomeworkQuestionsState {
  data: string[];
  question_corrected_response: string[];
  loading: boolean;
  question_getting_corrected: boolean;
  error: string | null;
  question_correcting_error: string | null;
}

const initialState: HomeworkQuestionsState = {
  data: [],
  question_corrected_response: [],
  loading: false,
  question_getting_corrected: false,
  error: null,
  question_correcting_error: null
};

const homeworkQuestionsReducer = (state = initialState, action: HomeworkQuestionsAction): HomeworkQuestionsState => {
  switch (action.type) {
    case HomeworkQuestionsActionTypes.HOMEWORK_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        question_corrected_response: [],
        question_getting_corrected: false,
        question_correcting_error: null
      };
    case HomeworkQuestionsActionTypes.HOMEWORK_QUESTIONS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HomeworkQuestionsActionTypes.HOMEWORK_QUESTIONS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case HomeworkQuestionsActionTypes.UPDATE_HOMEWORK_QUESTIONS_REQUEST:
      return { ...state, question_getting_corrected: true, question_corrected_response: [] };
    case HomeworkQuestionsActionTypes.UPDATE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        question_corrected_response: action.payload,
        question_getting_corrected: false,
        question_correcting_error: null
      };
    case HomeworkQuestionsActionTypes.UPDATE_HOMEWORK_QUESTIONS_REQUEST_ERROR:
      return {
        ...state,
        question_corrected_response: [],
        question_getting_corrected: false,
        question_correcting_error: action.payload
      };

    case HomeworkQuestionsActionTypes.DELETE_HOMEWORK_QUESTIONS_REQUEST:
      return { ...state, loading: true };
    case HomeworkQuestionsActionTypes.DELETE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case HomeworkQuestionsActionTypes.DELETE_HOMEWORK_QUESTIONS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default homeworkQuestionsReducer;
