import { SELECT_HOMEWORK } from '../actions/homeworkActions';

const initialState = {
  selectedHomework: null // Initial state for selected homework
  // ... other reducer state properties
};

const selectedHomeworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_HOMEWORK:
      return {
        ...state,
        selectedHomework: action.payload
      };
    // ... other cases

    default:
      return state;
  }
};

export default selectedHomeworkReducer;
