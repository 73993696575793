import { HomeworkQuestionsActionTypes, HomeworkQuestionsAction } from './HomeworkQuestionsActionTypes';

export const homeworkQuestionsRequest = (data: any): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.HOMEWORK_QUESTIONS_REQUEST,
  payload: data
});

export const homeworkQuestionsRequestSuccess = (data: any): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.HOMEWORK_QUESTIONS_REQUEST_SUCCESS,
  payload: data
});

export const homeworkQuestionsRequestError = (error: string): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.HOMEWORK_QUESTIONS_REQUEST_ERROR,
  payload: error
});

export const updateHomeworkQuestionsRequest = (data: any): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.UPDATE_HOMEWORK_QUESTIONS_REQUEST,
  payload: data
});

export const updateHomeworkQuestionsRequestSuccess = (data: any): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.UPDATE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS,
  payload: data
});

export const updateHomeworkQuestionsRequestError = (error: string): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.UPDATE_HOMEWORK_QUESTIONS_REQUEST_ERROR,
  payload: error
});

export const deleteHomeworkQuestionsRequest = (data: any): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.DELETE_HOMEWORK_QUESTIONS_REQUEST,
  payload: data
});

export const deleteHomeworkQuestionsRequestSuccess = (data: any): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.DELETE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS,
  payload: data
});

export const deleteHomeworkQuestionsRequestError = (error: string): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.DELETE_HOMEWORK_QUESTIONS_REQUEST_ERROR,
  payload: error
});

export const resetQuestionResponse = (): HomeworkQuestionsAction => ({
  type: HomeworkQuestionsActionTypes.RESET_QUESTION_RESPONSE_STATE
});
