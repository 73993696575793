export enum ManageEventsActionTypes {
  MANAGE_EVENTS = 'manageEvents/MANAGE_EVENTS',
  MANAGE_EVENTS_SUCCESS = 'manageEvents/MANAGE_EVENTS_SUCCESS',
  MANAGE_EVENTS_ERROR = 'manageEvents/MANAGE_EVENTS_ERROR'
}

export interface ManageEventsAction {
  type: ManageEventsActionTypes;
  payload?: any;
}
