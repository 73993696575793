export enum CreateClassActionTypes {
    SUBMIT_FORM = 'createClass/SUBMIT_FORM',
    SUBMIT_FORM_SUCCESS = 'createClass/SUBMIT_FORM_SUCCESS',
    SUBMIT_FORM_ERROR = 'createClass/SUBMIT_FORM_ERROR',
    }
    
    export interface CreateClassAction {
      type: CreateClassActionTypes;
      payload?: any;
    }
    