import { takeLatest, put, call } from 'redux-saga/effects';
import { AvailabilityActionTypes } from '../actions/setAvailabilityActionTypes';
import {
  getAvailabilityRequestSuccess,
  getAvailabilityRequestError,
  setAvailabilityRequestSuccess,
  setAvailabilityRequestError,
  updateAvailabilityRequestSuccess,
  updateAvailabilityRequestError,
  deleteAvailabilityRequestSuccess,
  deleteAvailabilityRequestError
} from '../actions/setAvailabilityActions';
import { apiService } from '../../../services/api';

function* getAvailabilityRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getAvailability, req.payload);
    // Dispatch the success action with the API response
    yield put(getAvailabilityRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getAvailabilityRequestError(error.message));
  }
}

function* setAvailabilityRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.setAvailability, req.payload);

    // Dispatch the success action with the API response
    yield put(setAvailabilityRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(setAvailabilityRequestError(error.message));
  }
}

function* updateAvailabilityRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.setAvailability, req.payload);

    // Dispatch the success action with the API response
    yield put(updateAvailabilityRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateAvailabilityRequestError(error.message));
  }
}

function* deleteAvailabilityRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.setAvailability, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteAvailabilityRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteAvailabilityRequestError(error.message));
  }
}

export function* watchAvailabilityRequest() {
  yield takeLatest(AvailabilityActionTypes.GET_AVAILABILITY_REQUEST, getAvailabilityRequestSaga);
  yield takeLatest(AvailabilityActionTypes.SET_AVAILABILITY_REQUEST, setAvailabilityRequestSaga);
  yield takeLatest(AvailabilityActionTypes.UPDATE_AVAILABILITY_REQUEST, updateAvailabilityRequestSaga);
  yield takeLatest(AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST, deleteAvailabilityRequestSaga);
}
