import { createReducer } from "@reduxjs/toolkit";
import { setTokens, logoutAction } from "./Gen3LearningActionCreators";

const initialState: any = {
  idToken: null,
  accessToken: null,
  user: null,
};

export const Gen3LearningReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setTokens, (state, action) => {
      state.idToken = action.payload.idToken;
      state.accessToken = action.payload.accessToken;
    })
    .addCase(logoutAction, (state) => {
      state.idToken = null;
      state.accessToken = null;
      state.user = null;
    });
});
