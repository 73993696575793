import { ScheduleActionTypes, ScheduleAction } from '../actions/getScheduleActionTypes';

interface ScheduleState {
  scheduleData: string[];
  data: string[];
  scheduleLoading: boolean;
  loading: boolean;
  scheduleLoaded: boolean;
  loaded: boolean;
  scheduleError: string | null;
  error: string | null;
}

const initialState: ScheduleState = {
  scheduleData: [],
  data: [],
  scheduleLoading: false,
  scheduleLoaded: false,
  loading: false,
  loaded: false,
  scheduleError: null,
  error: null
};

const scheduleReducer = (state = initialState, action: ScheduleAction): ScheduleState => {
  switch (action.type) {
    case ScheduleActionTypes.GET_SCHEDULE_REQUEST:
      return { ...state, scheduleLoading: true };
    case ScheduleActionTypes.GET_RESCHEDULE_REQUEST:
      return { ...state, loading: true };
    case ScheduleActionTypes.GET_RESCHEDULE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case ScheduleActionTypes.GET_RESCHEDULE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case ScheduleActionTypes.GET_SCHEDULE_REQUEST_SUCCESS:
      return {
        ...state,
        scheduleData: action.payload,
        scheduleLoading: false,
        scheduleLoaded: true,
        scheduleError: null
      };
    case ScheduleActionTypes.GET_SCHEDULE_REQUEST_ERROR:
      return { ...state, scheduleLoading: false, scheduleError: action.payload };
    case ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST:
      return { ...state, loading: true };
    case ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case ScheduleActionTypes.DELETE_SCHEDULE_REQUEST:
      return { ...state, loading: true };
    case ScheduleActionTypes.DELETE_SCHEDULE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case ScheduleActionTypes.DELETE_SCHEDULE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default scheduleReducer;
