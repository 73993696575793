export enum HomeworkQuestionsActionTypes {
  HOMEWORK_QUESTIONS_REQUEST = 'getHomeworkQuestions/HOMEWORK_QUESTIONS_REQUEST',
  HOMEWORK_QUESTIONS_REQUEST_SUCCESS = 'getHomeworkQuestions/HOMEWORK_QUESTIONS_REQUEST_SUCCESS',
  HOMEWORK_QUESTIONS_REQUEST_ERROR = 'getHomeworkQuestions/HOMEWORK_QUESTIONS_REQUEST_ERROR',
  UPDATE_HOMEWORK_QUESTIONS_REQUEST = 'getHomeworkQuestions/UPDATE_HOMEWORK_QUESTIONS_REQUEST',
  UPDATE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS = 'getHomeworkQuestions/UPDATE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS',
  UPDATE_HOMEWORK_QUESTIONS_REQUEST_ERROR = 'getHomeworkQuestions/UPDATE_HOMEWORK_QUESTIONS_REQUEST_ERROR',
  DELETE_HOMEWORK_QUESTIONS_REQUEST = 'getHomeworkQuestions/DELETE_HOMEWORK_QUESTIONS_REQUEST',
  DELETE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS = 'getHomeworkQuestions/DELETE_HOMEWORK_QUESTIONS_REQUEST_SUCCESS',
  DELETE_HOMEWORK_QUESTIONS_REQUEST_ERROR = 'getHomeworkQuestions/DELETE_HOMEWORK_QUESTIONS_REQUEST_ERROR'
}

export interface HomeworkQuestionsAction {
  type: HomeworkQuestionsActionTypes;
  payload?: any;
}
