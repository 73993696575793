import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { requestClassError, requestClassSuccess } from '../actions/requestClassActions';
import { RequestClassActionTypes } from '../actions/requestClassActionTypes';

function* requestClassSaga(req) {
  try {
    // Call the API using the call effect
    // TODO: change API functions
    // const response = yield call(apiService.requestClass, req.payload);
    const response = {};
    // Dispatch the success action with the API response
    yield put(requestClassSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(requestClassError(error.message));
  }
}

export function* watchRequestClass() {
  yield takeLatest(RequestClassActionTypes.REQUEST_CLASS_REQUEST, requestClassSaga);
}
