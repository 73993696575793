import { takeLatest, put, call } from 'redux-saga/effects';
import { HomeworkActionTypes } from '../actions/HomeworkActionTypes';
import {
  homeworkRequestSuccess, homeworkRequestError,
  updateHomeworkRequestSuccess, updateHomeworkRequestError,
  deleteHomeworkRequestSuccess, deleteHomeworkRequestError
} from '../actions/homeworkActions';
import { apiService } from '../../../services/api'; 

function* homeworkRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getHomework, req.payload);

    // Dispatch the success action with the API response
    yield put(homeworkRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(homeworkRequestError(error.message));
  }
}

function* updateHomeworkRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getHomework, req.payload);

    // Dispatch the success action with the API response
    yield put(updateHomeworkRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateHomeworkRequestError(error.message));
  }
}

function* deleteHomeworkRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getHomework, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteHomeworkRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteHomeworkRequestError(error.message));
  }
}

export function* watchHomeworkRequest() {
  yield takeLatest(HomeworkActionTypes.HOMEWORK_REQUEST, homeworkRequestSaga);
  yield takeLatest(HomeworkActionTypes.UPDATE_HOMEWORK_REQUEST, updateHomeworkRequestSaga);
  yield takeLatest(HomeworkActionTypes.DELETE_HOMEWORK_REQUEST, deleteHomeworkRequestSaga);
}
