//features/courseListing/actions/courseListingActions.ts
import { CourseListingActionTypes, CourseListingAction } from './CourseListingActionTypes';

export const courseListingRequest = (data: any): CourseListingAction => ({
  type: CourseListingActionTypes.COURSE_LISTING_REQUEST,
  payload: data
});

export const courseListingRequestSuccess = (data: any): CourseListingAction => ({
  type: CourseListingActionTypes.COURSE_LISTING_REQUEST_SUCCESS,
  payload: data
});

export const courseListingRequestError = (error: string): CourseListingAction => ({
  type: CourseListingActionTypes.COURSE_LISTING_REQUEST_ERROR,
  payload: error
});

export const courseListingRequestTeachers = (data: any): CourseListingAction => ({
  type: CourseListingActionTypes.COURSE_LISTING_REQUEST_TEACHERS,
  payload: data
});

export const courseListingRequestTeachersSuccess = (data: any): CourseListingAction => ({
  type: CourseListingActionTypes.COURSE_LISTING_REQUEST_TEACHERS_SUCCESS,
  payload: data
});

export const courseListingRequestTeachersError = (error: string): CourseListingAction => ({
  type: CourseListingActionTypes.COURSE_LISTING_REQUEST_TEACHERS_ERROR,
  payload: error
});
