
export enum NewPasswordActionTypes {
  NEW_PASSWORD_USER = 'newPassword/NEW_PASSWORD_USER',
  NEW_PASSWORD_USER_SUCCESS = 'newPassword/NEW_PASSWORD_USER_SUCCESS',
  NEW_PASSWORD_USER_ERROR = 'newPassword/NEW_PASSWORD_USER_ERROR',
}

export interface NewPasswordAction {
  type: NewPasswordActionTypes;
  payload?: any;
}
