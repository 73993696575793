import { takeLatest, put, call } from 'redux-saga/effects';
import { CourseListingActionTypes } from '../actions/CourseListingActionTypes';
import { courseListingRequestSuccess, courseListingRequestError } from '../actions/courseListingActions';
import { apiService } from '../../../services/api';
import {
  courseListingRequestTeachersSuccess,
  courseListingRequestTeachersError
} from '../actions/courseListingActions';

function* courseListingRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.courseListing, req.payload);

    // Dispatch the success action with the API response
    yield put(courseListingRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(courseListingRequestError(error.message));
  }
}

function* courseListingRequestTeachersSaga(req) {
  try {
    const response = yield call(apiService.courseListing, req.payload);
    yield put(courseListingRequestTeachersSuccess(response));
  } catch (error: any) {
    yield put(courseListingRequestTeachersError(error.message));
  }
}

export function* watchCourseListingRequest() {
  yield takeLatest(CourseListingActionTypes.COURSE_LISTING_REQUEST, courseListingRequestSaga);
  yield takeLatest(CourseListingActionTypes.COURSE_LISTING_REQUEST_TEACHERS, courseListingRequestTeachersSaga);
}
