export enum CourseDetailActionTypes {
  COURSE_DETAIL_REQUEST = 'courseDetail/COURSE_DETAIL_REQUEST',
  COURSE_DETAIL_REQUEST_SUCCESS = 'courseDetail/COURSE_DETAIL_REQUEST_SUCCESS',
  COURSE_DETAIL_REQUEST_ERROR = 'courseDetail/COURSE_DETAIL_REQUEST_ERROR'
}

export interface CourseDetailAction {
  type: CourseDetailActionTypes;
  payload?: any;
}
