export enum FollowTeacherActionTypes {
  FOLLOW_TEACHER_REQUEST = 'followTeacher/FOLLOW_TEACHER_REQUEST',
  FOLLOW_TEACHER_REQUEST_SUCCESS = 'followTeacher/FOLLOW_TEACHER_REQUEST_SUCCESS',
  FOLLOW_TEACHER_REQUEST_ERROR = 'followTeacher/FOLLOW_TEACHER_REQUEST_ERROR'
}

export interface FollowTeacherAction {
  type: FollowTeacherActionTypes;
  payload?: any;
}
