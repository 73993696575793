// features/resendCode/actions/resendCodeActions.ts
import { ResendCodeActionTypes, ResendCodeAction } from "./ResendCodeActionTypes";

export const resendCodeUser = (req: any): ResendCodeAction => ({
  type: ResendCodeActionTypes.VERIFY_PASSWORD_USER,
  payload: req,
});

export const resendCodeUserSuccess = (data: any): ResendCodeAction => ({
  type: ResendCodeActionTypes.VERIFY_PASSWORD_USER_SUCCESS,
  payload: data,
});

export const resendCodeUserError = (error: string): ResendCodeAction => ({
  type: ResendCodeActionTypes.VERIFY_PASSWORD_USER_ERROR,
});