import { CourseDetailActionTypes, CourseDetailAction } from './CourseDetailActionTypes';

export const courseDetailRequest = (data: any): CourseDetailAction => ({
  type: CourseDetailActionTypes.COURSE_DETAIL_REQUEST,
  payload: data
});

export const courseDetailRequestSuccess = (data: any): CourseDetailAction => ({
  type: CourseDetailActionTypes.COURSE_DETAIL_REQUEST_SUCCESS,
  payload: data
});

export const courseDetailRequestError = (error: string): CourseDetailAction => ({
  type: CourseDetailActionTypes.COURSE_DETAIL_REQUEST_ERROR,
  payload: error
});
