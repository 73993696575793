
export enum ResendCodeActionTypes {
  VERIFY_PASSWORD_USER = 'resendCode/VERIFY_PASSWORD_USER',
  VERIFY_PASSWORD_USER_SUCCESS = 'resendCode/VERIFY_PASSWORD_USER_SUCCESS',
  VERIFY_PASSWORD_USER_ERROR = 'resendCode/VERIFY_PASSWORD_USER_ERROR',
}

export interface ResendCodeAction {
  type: ResendCodeActionTypes;
  payload?: any;
}
