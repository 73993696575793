import { getEventsActionTypes, getEventsAction } from '../actions/getEventsActionTypes';

interface getEventsState {
  data: string[];
  loading: boolean;
  load_error: string | null;
}

const initialState: getEventsState = {
  data: [],
  loading: false,
  load_error: null
};

const getEventsReducer = (state = initialState, action: getEventsAction): getEventsState => {
  switch (action.type) {
    case getEventsActionTypes.GET_EVENTS:
      return { ...state, loading: true };
    case getEventsActionTypes.GET_EVENTS_SUCCESS:
      return { ...state, data: action.payload, loading: false, load_error: null };
    case getEventsActionTypes.GET_EVENTS_ERROR:
      return { ...state, loading: false, load_error: action.payload };
    default:
      return state;
  }
};

export default getEventsReducer;
