import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageResourcesActionTypes } from '../actions/manageResourcesActionTypes';
import { apiService } from '../../../services/api';

function* manageResourcesRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageResources, req.payload);

    // Dispatch the success action with the API response
  } catch (error: any) {
    // Dispatch the error action
  }
}

export function* watchmanageResourcesRequest() {
  yield takeLatest(ManageResourcesActionTypes.MANAGE_RESOURCES, manageResourcesRequestSaga);
}
