import { takeLatest, put, call } from 'redux-saga/effects';
import { ScheduleActionTypes } from '../actions/getScheduleActionTypes';
import {
  getScheduleRequestSuccess,
  getScheduleRequestError,
  updateScheduleRequestSuccess,
  updateScheduleRequestError,
  deleteScheduleRequestSuccess,
  deleteScheduleRequestError,
  getRescheduleRequestSuccess,
  getRescheduleRequestError
} from '../actions/getScheduleActions';
import { apiService } from '../../../services/api';

function* getScheduleRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getSchedule, req.payload);

    // Dispatch the success action with the API response
    yield put(getScheduleRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getScheduleRequestError(error.message));
  }
}

function* getRescheduleRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.requestCourseReschedule, req.payload);

    // Dispatch the success action with the API response
    yield put(getRescheduleRequestSuccess(response?.message));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getRescheduleRequestError(error.message));
  }
}

function* updateScheduleRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getSchedule, req.payload);

    // Dispatch the success action with the API response
    yield put(updateScheduleRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateScheduleRequestError(error.message));
  }
}

function* deleteScheduleRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getSchedule, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteScheduleRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteScheduleRequestError(error.message));
  }
}

export function* watchScheduleRequest() {
  yield takeLatest(ScheduleActionTypes.GET_SCHEDULE_REQUEST, getScheduleRequestSaga);
  yield takeLatest(ScheduleActionTypes.GET_RESCHEDULE_REQUEST, getRescheduleRequestSaga);
  yield takeLatest(ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST, updateScheduleRequestSaga);
  yield takeLatest(ScheduleActionTypes.DELETE_SCHEDULE_REQUEST, deleteScheduleRequestSaga);
}
