export enum ClassNotesActionTypes {
  CLASS_NOTES_REQUEST = 'classNotes/CLASS_NOTES_REQUEST',
  CLASS_NOTES_REQUEST_SUCCESS = 'classNotes/CLASS_NOTES_REQUEST_SUCCESS',
  CLASS_NOTES_REQUEST_ERROR = 'classNotes/CLASS_NOTES_REQUEST_ERROR',
  CLASS_NOTES_UPDATE_REQUEST = 'classNotes/CLASS_NOTES_UPDATE_REQUEST',
  CLASS_NOTES_UPDATE_REQUEST_SUCCESS = 'classNotes/CLASS_NOTES_UPDATE_REQUEST_SUCCESS',
  CLASS_NOTES_UPDATE_REQUEST_ERROR = 'classNotes/CLASS_NOTES_UPDATE_REQUEST_ERROR',
  CLASS_NOTES_DELETE_REQUEST = 'classNotes/CLASS_NOTES_DELETE_REQUEST',
  CLASS_NOTES_DELETE_REQUEST_SUCCESS = 'classNotes/CLASS_NOTES_DELETE_REQUEST_SUCCESS',
  CLASS_NOTES_DELETE_REQUEST_ERROR = 'classNotes/CLASS_NOTES_DELETE_REQUEST_ERROR'
}

export interface ClassNotesAction {
  type: ClassNotesActionTypes;
  payload?: any;
}
