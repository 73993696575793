// features/login/sagas/loginSaga.ts
import { all, takeLatest, put, call, delay } from 'redux-saga/effects';
import { ConfirmRegistrationActionTypes } from '../actions/ConfirmRegistrationActionTypes';
import { confirmRegistrationSuccess, confirmRegistrationError } from '../actions/confirmRegistrationActions';
import { apiService } from '../../../services/api'; // Update the path as needed
import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';

const AWS = require('aws-sdk');
AWS.config.update({ region: 'us-east-1' });
const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

function* confirmRegistrationSaga(action) {
  try {
    const req = action.payload;

    const data = req;
    const verify_code_values = {
      email: data.email,
      verification_code: data.verification_code
    };
    localStorage.setItem('user_name_with_email', verify_code_values.email);
    const params = {
      ClientId: localStorage.getItem('client_id'),
      ConfirmationCode: verify_code_values.verification_code,
      Username: verify_code_values.email.split('@')[0]
    };
    try {
      const authPromise = new Promise((resolve, reject) => {
        cognitoIdentityServiceProvider.confirmSignUp(params).promise();
      });
      const confirm_registration_response = yield call(apiService.ConfirmRegistration, req);
      yield put(confirmRegistrationSuccess(confirm_registration_response));
    } catch (error: any) {
      yield put(confirmRegistrationError(error));
    }
  } catch (error: any) {
    yield put(confirmRegistrationError(error));
  }
}

export function* watchConfirmRegistration() {
  yield takeLatest(ConfirmRegistrationActionTypes.CONFIRM_REGISTRATION, confirmRegistrationSaga);
}
