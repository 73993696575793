import { takeLatest, put } from 'redux-saga/effects';
import { HeaderActionTypes } from './constants';
import { headerTitleSuccess, headerTitleError } from './actions';

function* headerTitleSetSaga(req) {
  try {
    // Call the API using the call effect
    //

    // Dispatch the success action with the API response
    yield put(headerTitleSuccess(req.payload));
  } catch (error: any) {
    // Dispatch the error action
    yield put(headerTitleError(error.message));
  }
}

export function* watchHeaderTitleRequest() {
  yield takeLatest(HeaderActionTypes.HEADER_TITLE, headerTitleSetSaga);
}
