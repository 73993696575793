export enum TutorsActionTypes {
  TUTORS_REQUEST = 'getTutorRequest/TUTORS_REQUEST',
  TUTORS_REQUEST_SUCCESS = 'getTutorRequest/TUTORS_REQUEST_SUCCESS',
  TUTORS_REQUEST_ERROR = 'getTutorRequest/TUTORS_REQUEST_ERROR',
  SUBMIT_TUTORS_REQUEST = 'getTutorRequest/SUBMIT_TUTORS_REQUEST',
  SUBMIT_TUTORS_REQUEST_SUCCESS = 'getTutorRequest/SUBMIT_TUTORS_REQUEST_SUCCESS',
  SUBMIT_TUTORS_REQUEST_ERROR = 'getTutorRequest/SUBMIT_TUTORS_REQUEST_ERROR'
}

export interface TutorsAction {
  type: TutorsActionTypes;
  payload?: any;
}
