// features/verifyPassword/actions/verifyPasswordActions.ts
import { VerifyPasswordActionTypes, VerifyPasswordAction } from "./VerifyPasswordActionTypes";

export const verifyPasswordUser = (req: any): VerifyPasswordAction => ({
  type: VerifyPasswordActionTypes.VERIFY_PASSWORD_USER,
  payload: req,
});

export const verifyPasswordUserSuccess = (data: any): VerifyPasswordAction => ({
  type: VerifyPasswordActionTypes.VERIFY_PASSWORD_USER_SUCCESS,
  payload: data,
});

export const verifyPasswordUserError = (error: string): VerifyPasswordAction => ({
  type: VerifyPasswordActionTypes.VERIFY_PASSWORD_USER_ERROR,
});