// features/login/reducers/loginReducer.ts
import { ChangePasswordActionTypes, ChangePasswordAction } from "../actions/ChangePasswordActionTypes";

interface ChangePasswordState {
  user: string | null;
  loading: boolean;
  error: string | null;
  loggedIn: boolean;
}

const initialState: ChangePasswordState = {
  user: null,
  loading: false,
  error: null,
  loggedIn: false,
};

const changePasswordReducer = (state = initialState, action: ChangePasswordAction): ChangePasswordState => {
  switch (action.type) {
    case ChangePasswordActionTypes.CHANGE_PASSWORD_USER:
      return { ...state, loading: true };
    case ChangePasswordActionTypes.CHANGE_PASSWORD_USER_SUCCESS:
      return { ...state, loading: false, error: null };
    case ChangePasswordActionTypes.CHANGE_PASSWORD_USER_ERROR:
      return { ...state, loading: false, error: action.payload };    
    default:
      return state;
  }
};

export default changePasswordReducer;
