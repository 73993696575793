import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { courseNotificationError, courseNotificationSuccess } from '../actions/courseNotificationActions';
import { CourseNotificationActionTypes } from '../actions/courseNotificationActionTypes';

function* courseNotificationSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.courseNotification, req.payload);
    // Dispatch the success action with the API response
    yield put(courseNotificationSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(courseNotificationError(error.message));
  }
}

export function* watchCourseNotification() {
  yield takeLatest(CourseNotificationActionTypes.COURSE_NOTIFICATION_REQUEST, courseNotificationSaga);
}
