// coursesSlice.ts
import axios from '../../Utils/axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../configureStore';

const ENV = process.env.REACT_APP_LANE;
const BASE_URL = `https://${process.env.REACT_APP_BASE_URL}/${ENV}`;

interface CourseDetailState {
  courseDetail: any[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: CourseDetailState = {
  courseDetail: null,
  loading: false,
  error: null
};

const CourseDetailSlice = createSlice({
  name: 'CourseDetail',
  initialState,
  reducers: {
    fetchCourseDetailStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchCourseDetailSuccess: (state, action) => {
      state.loading = false;
      state.courseDetail = action.payload;
    },
    fetchCourseDetailFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    SelectCourseDetail: (state, action) => {
      state.courseDetail = action.payload;
    },
    updateCourseDetailStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateCourseDetailSuccess: (state, action) => {
      state.loading = false;
      state.courseDetail = action.payload;
    },
    updateCourseDetailFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  fetchCourseDetailStart,
  fetchCourseDetailSuccess,
  fetchCourseDetailFailure,
  SelectCourseDetail,
  updateCourseDetailStart,
  updateCourseDetailSuccess,
  updateCourseDetailFailure
} = CourseDetailSlice.actions;

export const selectCourseDetail = (state) => state.courseDetail;

export const fetchCourseDetail = (course_param: any[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchCourseDetailStart());
    const response = await axios.post(`${BASE_URL}/getCourseDetail`, course_param); // Replace with your actual API call
    dispatch(fetchCourseDetailSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchCourseDetailFailure(error.message));
  }
};

export const updateCourseDetail = (updatedData: CourseDetailState) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateCourseDetailStart());
    const response = await axios.post(`${BASE_URL}/updateCourseDetail`, updatedData); // Replace with your actual API call
    dispatch(updateCourseDetailSuccess(response.data));
  } catch (error: any) {
    dispatch(updateCourseDetailFailure(error.message));
  }
};

export default CourseDetailSlice.reducer;
