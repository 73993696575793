import { HomeworkPagesActionTypes, HomeworkPagesAction } from './HomeworkPagesActionTypes';

export const homeworkPagesRequest = (data: any): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.HOMEWORK_PAGES_REQUEST,
  payload: data
});

export const homeworkPagesRequestSuccess = (data: any): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.HOMEWORK_PAGES_REQUEST_SUCCESS,
  payload: data
});

export const homeworkPagesRequestError = (error: string): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.HOMEWORK_PAGES_REQUEST_ERROR,
  payload: error
});

export const updateHomeworkPagesRequest = (data: any): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.UPDATE_HOMEWORK_PAGES_REQUEST,
  payload: data
});

export const updateHomeworkPagesRequestSuccess = (data: any): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.UPDATE_HOMEWORK_PAGES_REQUEST_SUCCESS,
  payload: data
});

export const updateHomeworkPagesRequestError = (error: string): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.UPDATE_HOMEWORK_PAGES_REQUEST_ERROR,
  payload: error
});

export const deleteHomeworkPagesRequest = (data: any): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.DELETE_HOMEWORK_PAGES_REQUEST,
  payload: data
});

export const deleteHomeworkPagesRequestSuccess = (data: any): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.DELETE_HOMEWORK_PAGES_REQUEST_SUCCESS,
  payload: data
});

export const deleteHomeworkPagesRequestError = (error: string): HomeworkPagesAction => ({
  type: HomeworkPagesActionTypes.DELETE_HOMEWORK_PAGES_REQUEST_ERROR,
  payload: error
});
