export enum getClassesActionTypes {
  GET_CLASSES = 'getClasses/GET_CLASSES',
  GET_CLASSES_SUCCESS = 'getClasses/GET_CLASSES_SUCCESS',
  GET_CLASSES_ERROR = 'getClasses/GET_CLASSES_ERROR'
}

export interface getClassesAction {
  type: getClassesActionTypes;
  payload?: any;
}
