import { lazy } from 'react';
import Loadable from '../Pages/LayoutPage/Shared/loadable/Loadable';
import { createBrowserRouter, Navigate } from 'react-router-dom';
const RescheduleRequests = Loadable(lazy(() => import('../Pages/RescheduleRequests')));
const ProtectedRoute = Loadable(lazy(() => import('./ProtectedRoute')));
const SidebarLayout = Loadable(lazy(() => import('../Pages/LayoutPage/SidebarLayout')));
const BaseLayout = Loadable(lazy(() => import('../Pages/LayoutPage/BaseLayout')));
const SignIn = Loadable(lazy(() => import('../Pages/Signin/SignIn')));
const ClassNotes = Loadable(lazy(() => import('../Pages/ClassNotes/ClassNotes')));
const Gen3Classes = Loadable(lazy(() => import('../Pages/Gen3Classes/Gen3Classes')));
const Students = Loadable(lazy(() => import('../Pages/Students/Students')));
const Applicants = Loadable(lazy(() => import('../Pages/JoinTeam/review/index')));
const ManageCategories = Loadable(lazy(() => import('../Pages/Admin/Categories/ManageCategories')));
const ManageCourses = Loadable(lazy(() => import('../Pages/Admin/Courses/ManageCourses')));
const Events = Loadable(lazy(() => import('../Pages/Events/Events')));
const CreateEvent = Loadable(lazy(() => import('../Pages/Events/pages/CreateEvent')));
const EventDetails = Loadable(lazy(() => import('../Pages/Events/pages/EventDetails')));
const ContactUs = Loadable(lazy(() => import('../Pages/ContactUs/ContactUs')));
const JoinTeam = Loadable(lazy(() => import('../Pages/JoinTeam/JoinTeamPage')));
const Dashboard = Loadable(lazy(() => import('../Pages/Dashboard/Dashboard')));
const KGStudentDashboard = Loadable(lazy(() => import('../Pages/Dashboard/KGStudentDashboard')));
const ForgotPassword = Loadable(lazy(() => import('../Pages/ForgotPassword/ForgotPassword')));
const VerifyPassword = Loadable(lazy(() => import('../Pages/ForgotPassword/VerifyPassword')));
const HomePage = Loadable(lazy(() => import('../Pages/HomePage/HomePage')));
const Resources = Loadable(lazy(() => import('../Pages/HomePage/Resources')));
const Homework = Loadable(lazy(() => import('../Pages/Homework/Homework')));
const CreateHomeworkQuestions = Loadable(lazy(() => import('../Pages/Homework/CreateHomeworkQuestions')));
const EnrolledSubjects = Loadable(lazy(() => import('../Pages/Homework/EnrolledSubjects')));
const Rewards = Loadable(lazy(() => import('../Pages/Rewards/Rewards')));
const EarnedGifts = Loadable(lazy(() => import('../Pages/Rewards/EarnedGifts')));
const CompletedHomeworks = Loadable(lazy(() => import('../Pages/Homework/CompletedHomework')));
const HomeworkList = Loadable(lazy(() => import('../Pages/Homework/HomeworkList')));
const HomeworkPageList = Loadable(lazy(() => import('../Pages/Homework/HomeworkPages')));
const Games = Loadable(lazy(() => import('../Pages/Games/index')));
const Messenger = Loadable(lazy(() => import('../Pages/Messenger')));
const Users = Loadable(lazy(() => import('../Pages/Admin/Users')));
const Schedule = Loadable(lazy(() => import('../Pages/Schedule/Schedule')));
const Reschedule = Loadable(lazy(() => import('../Pages/Schedule/Reschedule')));
const UpdateSchedule = Loadable(lazy(() => import('../Pages/Schedule/components/SetSchedule')));
const Signup = Loadable(lazy(() => import('../Pages/Signup/Signup')));
const Trial = Loadable(lazy(() => import('../Pages/Signup/Trial')));
const VerifyUser = Loadable(lazy(() => import('../Pages/Signup/VerifyUser')));
const ResendCode = Loadable(lazy(() => import('../Pages/Signup/ResendCode')));
const CourseDetails = Loadable(lazy(() => import('../Pages/Courses/Details/index')));
const UserSettings = Loadable(lazy(() => import('../Pages/Users/settings')));
const UserProfile = Loadable(lazy(() => import('../Pages/Admin/Users/single')));
const PublicUserProfile = Loadable(lazy(() => import('../Pages/Admin/Users/single/TeachersIndex')));
const Support = Loadable(lazy(() => import('../Pages/Users/support')));
const Error = Loadable(lazy(() => import('../Pages/LayoutPage/Shared/error/Error')));
const PublicRoute = Loadable(lazy(() => import('./PublicRoute')));
const HomeworkManager = Loadable(lazy(() => import('../Pages/Homework/HomeworkManager')));
const CreateCourse = Loadable(lazy(() => import('../Pages/Courses/CreateCourse')));
const CreateModelEvents = Loadable(lazy(() => import('../Pages/Events/pages/CreateModelEvents')));
const PaymentSuccess = Loadable(lazy(() => import('../Pages/Payments/PaymentSuccess')));
const ManageLocations = Loadable(lazy(() => import('../Pages/Admin/Locations/ManageLocations')));
const Router = [
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/settings', element: <UserSettings /> },
      { path: '/profile/:userId', element: <UserProfile /> },
      { path: '/messages', element: <Messenger /> },
      { path: '/schedules', element: <Schedule /> },
      { path: '/reschedule/:rescheduleDetails', element: <Reschedule /> },
      { path: '/rescheduleRequests', element: <RescheduleRequests /> },
      { path: '/updateSchedule', element: <UpdateSchedule /> },
      { path: '/enrolledSubjects', element: <EnrolledSubjects /> },
      { path: '/homeworks', element: <HomeworkList /> },
      { path: '/homeworks/homeworkProblems', element: <Homework /> },
      { path: '/homework/homeworkProblems/:hwdetails', element: <Homework /> },
      { path: '/homeworks/:subject', element: <HomeworkList /> },
      { path: '/createHomeworkQuestions', element: <CreateHomeworkQuestions /> },
      { path: '/completedHomeworks', element: <CompletedHomeworks /> },
      { path: '/games', element: <Games /> },
      { path: '/homeworks/homeworkId', element: <HomeworkPageList /> },
      { path: '/rewards', element: <Rewards /> },
      { path: '/earnedGifts', element: <EarnedGifts /> },
      { path: '/classnotes', element: <ClassNotes /> },
      { path: '/classes', element: <Gen3Classes /> },
      { path: '/createCourse', element: <CreateCourse /> },
      { path: '/createModelEvents', element: <CreateModelEvents /> },
      { path: '/students', element: <Students /> },
      { path: '/applicants', element: <Applicants /> },
      { path: '/manageCategories', element: <ManageCategories /> },
      { path: '/manageCourses', element: <ManageCourses /> },
      { path: '/manageLocations', element: <ManageLocations /> },
      { path: '/users', element: <Users /> },
      { path: '/contactSupport', element: <Support /> },
      { path: 'users/management/users/single/:userId', element: <UserProfile /> },
      { path: '/homeworkManager', element: <HomeworkManager /> },
      { path: '/payments/success', element: <PaymentSuccess /> },
      { path: '/createEvent', element: <CreateEvent /> },
      { path: '/createModelEvents', element: <CreateModelEvents /> },
      { path: '*', element: <Error /> }
    ]
  },
  {
    path: '/kgDashboard',
    element: (
      <ProtectedRoute>
        <KGStudentDashboard />
      </ProtectedRoute>
    )
  },
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/signin',
        element: (
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        )
      },
      { path: '/homepage', element: <HomePage /> },
      { path: '/resources', element: <Resources /> },
      { path: '/signout', element: <SignIn /> },
      { path: '/signup', element: <Signup /> },
      { path: '/trial', element: <Trial /> },
      { path: '/events', element: <Events /> },
      { path: '/event/:eventId', element: <EventDetails /> },
      { path: '/teachers/:userId', element: <PublicUserProfile /> },
      { path: '/ContactUs', element: <ContactUs /> },
      { path: '/CourseDetailPage', element: <CourseDetails /> },
      { path: '/forgotPassword', element: <ForgotPassword /> },
      { path: '/verifyPassword', element: <VerifyPassword /> },
      { path: '/verifyUser', element: <VerifyUser /> },
      { path: '/resendCode', element: <ResendCode /> },
      { path: '/joinTeam', element: <JoinTeam /> },
      { path: '/payments/success', element: <PaymentSuccess /> },
      { path: '*', element: <Error /> }
    ]
  }
];

export const router = createBrowserRouter(Router);

export default Router;
