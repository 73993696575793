import {
  BringCourseToYourLocationAction,
  BringCourseToYourLocationActionTypes
} from '../actions/bringCourseToYourLocationActionTypes';

interface BringCourseToYourLocationState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: BringCourseToYourLocationState = {
  data: [],
  loading: false,
  error: null
};

const bringCourseToYourLocationReducer = (
  state = initialState,
  action: BringCourseToYourLocationAction
): BringCourseToYourLocationState => {
  switch (action.type) {
    case BringCourseToYourLocationActionTypes.BRING_COURSE_REQUEST:
      return { ...state, loading: true, data: action.payload };
    case BringCourseToYourLocationActionTypes.BRING_COURSE_REQUEST_SUCCESS:
      return { ...state, loading: false, error: null };
    case BringCourseToYourLocationActionTypes.BRING_COURSE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default bringCourseToYourLocationReducer;
