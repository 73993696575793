import { MessagesActionTypes, MessagesAction } from '../actions/GetMessagesActionTypes';

interface MessagesState {
  data: string[];
  loading: boolean;
  loaded: boolean;
  updated: boolean;
  error: string | null;
}

const initialState: MessagesState = {
  data: [],
  loading: false,
  loaded: false,
  updated: false,
  error: null
};

const messagesReducer = (state = initialState, action: MessagesAction): MessagesState => {
  switch (action.type) {
    case MessagesActionTypes.MESSAGES_REQUEST:
      return { ...state, loading: true };
    case MessagesActionTypes.MESSAGES_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case MessagesActionTypes.MESSAGES_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case MessagesActionTypes.UPDATE_MESSAGES_REQUEST:
      return { ...state, loading: true };
    case MessagesActionTypes.UPDATE_MESSAGES_REQUEST_SUCCESS:
      return { ...state, loading: false, updated: true, error: null };
    case MessagesActionTypes.UPDATE_MESSAGES_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case MessagesActionTypes.DELETE_MESSAGES_REQUEST:
      return { ...state, loading: true };
    case MessagesActionTypes.DELETE_MESSAGES_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case MessagesActionTypes.DELETE_MESSAGES_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default messagesReducer;
